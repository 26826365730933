import React from "react";

const IcWhiteLockUnlocked = (props: any) => {
  return (
    <div {...props}>
      <svg width="22.4" height="22.4" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 15.3681C5 14.3631 5.39925 13.3992 6.10991 12.6885C6.82058 11.9779 7.78444 11.5786 8.78947 11.5786H23.9474C24.9524 11.5786 25.9163 11.9779 26.6269 12.6885C27.3376 13.3992 27.7368 14.3631 27.7368 15.3681V24.2102C27.7368 25.2152 27.3376 26.1791 26.6269 26.8898C25.9163 27.6004 24.9524 27.9997 23.9474 27.9997H8.78947C7.78444 27.9997 6.82058 27.6004 6.10991 26.8898C5.39925 26.1791 5 25.2152 5 24.2102V15.3681ZM17.6316 17.8944C17.6316 17.5594 17.4985 17.2381 17.2616 17.0012C17.0247 16.7643 16.7034 16.6312 16.3684 16.6312C16.0334 16.6312 15.7121 16.7643 15.4752 17.0012C15.2383 17.2381 15.1053 17.5594 15.1053 17.8944V21.6839C15.1053 22.0189 15.2383 22.3402 15.4752 22.5771C15.7121 22.814 16.0334 22.947 16.3684 22.947C16.7034 22.947 17.0247 22.814 17.2616 22.5771C17.4985 22.3402 17.6316 22.0189 17.6316 21.6839V17.8944Z"
          fill="currentColor"
        />
        <path
          d="M11.3164 12.8421V6.05263C11.3164 4.71259 11.8487 3.42743 12.7963 2.47988C13.7438 1.53233 15.029 1 16.369 1C17.7091 1 18.9942 1.53233 19.9418 2.47988C20.8893 3.42743 21.4217 4.71259 21.4217 6.05263V6.84211"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default IcWhiteLockUnlocked;
