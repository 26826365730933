import { IcLock } from "@/assets";
import React from "react";

const RemainingTimeCountdown = ({ remainingTime }: { remainingTime: number }) => {
  return (
    <div className="text-black text-base text-center flex-col items-center justify-center">
      <IcLock className="text-black flex  items-center justify-center" />
      <span className="text-xs">{remainingTime > 9 ? `00:${remainingTime}` : `00:0${remainingTime}`}</span>
    </div>
  );
};

export default React.memo(RemainingTimeCountdown);
