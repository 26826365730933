import { create } from "zustand";

interface IStore {
  flipCards: any[];
  bonusFlipCards: any[];
  challenges: any[];
  revealBonusIds: string;
  setReavealBonusIds: (revealBonusIds: string) => void;
  setChallenges: (challenges: any) => void;
  setbonusFlipCards: (bonusFlipCards: any) => void;
  setFlipCards: (flipCards: any) => void;
  shouldFlipGoldenCard: boolean | null;
  setFlipGoldenCard: (shouldFlipGoldenCard: boolean | null) => void;
}
const useFlipCardStore = create<IStore>((set: any) => ({
  flipCards: [],
  bonusFlipCards: [],
  challenges: [],
  revealBonusIds: "",
  setReavealBonusIds: (revealBonusIds: string) => set((state: any) => ({ ...state, revealBonusIds })),
  setChallenges: (challenges: any) => set((state: any) => ({ ...state, challenges })),
  setbonusFlipCards: (bonusFlipCards: any) => set((state: any) => ({ ...state, bonusFlipCards })),
  setFlipCards: (flipCards: any) => set((state: any) => ({ ...state, flipCards })),
  shouldFlipGoldenCard: null,
  setFlipGoldenCard: (shouldFlipGoldenCard: boolean | null) => set((state: any) => ({ ...state, shouldFlipGoldenCard })),
}));
export default useFlipCardStore;
