import { claimFlipCard } from "@/services/api";

export function isChallengeCompleted(challenges: any, staticChallengeId: string) {
  const challenge: any = challenges?.find((challenge: any) => challenge.challengeStaticId === staticChallengeId);
  return challenge?.isChallengeCompleted ?? false;
}

export function getChallengeByChallengeStaticId(challenges: any, staticChallengeId: string) {
  const challenge = challenges?.find((challenge: any) => challenge.challengeStaticId === staticChallengeId);
  return challenge;
}

export function isFlipCardClaimed(flipCards: any, flipCardStaticId: string) {
  const flipCard = flipCards?.find((flipCard: any) => flipCard.flipCardStaticId === flipCardStaticId);

  return flipCard?.isFlipCardClaimed;
}

export function isBonusCardDestroyed(bonusFlipCards: any, flipCardStaticId: string, flipCards: any) {
  const bonusCardDetails = bonusFlipCards?.find((flipCard: any) => flipCard.flipCardStaticId === flipCardStaticId);

  if (!bonusCardDetails) {
    return false;
  }

  if (bonusCardDetails) {
    const isAssociatedFlipCardsClaimed = bonusCardDetails.associatedFlipCardIds?.some((flipCardId: string) => {
      const isThisFlipCardClaimed = flipCards?.find((flipCard: any) => flipCard.flipCardId === flipCardId)?.isFlipCardClaimed;
      return isThisFlipCardClaimed;
    });

    return isAssociatedFlipCardsClaimed;
  }
  return false;
}

export const isChallengeExpired = (challenges: any, staticChallengeId: string) => {
  const challenge: any = challenges?.find((challenge: any) => {
    return challenge.challengeStaticId === staticChallengeId;
  });
  return challenge?.isExpired ?? false;
};

export function claimFlipCardService(flipCardId: string) {
  return claimFlipCard(flipCardId);
}
