import { IcChecked, IcLock, IcPatternHex, IcWhiteLockUnlocked } from "@/assets";
import { isChallengeCompleted, isFlipCardClaimed, isBonusCardDestroyed, isChallengeExpired } from "./ChallengeService";
import useFlipCardStore from "@/store/useFlipCardStore";
import FlipCard from "./Reward";
import RemainingTimeCountdown from "@/components/UI/RemainingTimeCountdown";
import toast from "react-hot-toast";

const ChallengeRow1 = ({ remainingTime, isQuestLocked, setFlipCardModalOpen, refetch, refetchStatistics, setDetailsToShowInModal, setLoading }: any) => {
  const { challenges, flipCards, bonusFlipCards } = useFlipCardStore();

  return (
    <div className="grid relative">
      {/* RW1 */}
      <FlipCard flipCardClass="bottom-[-151%] right-[-75%]" flipCardId="RW1" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* CH1 */}
      <div className="w-[116.2px] relative aspect-[1/0.9]">
        <div
          className={`z-50 hexagon ${
            isChallengeCompleted(challenges, "CH1")
              ? isChallengeExpired(challenges, "CH1")
                ? "bg-gray-500"
                : "bg-green-500"
              : isChallengeExpired(challenges, "CH1")
              ? "bg-gray-500"
              : "bg-green-500"
          }  absolute bottom-[-100.5%]`}
          onClick={() => {
            isQuestLocked("CH1", true)
              ? ""
              : isChallengeCompleted(challenges, "CH1")
              ? ""
              : isChallengeExpired(challenges, "CH1")
              ? ""
              : remainingTime > 0
              ? toast.error("Please wait until challenge is unlocked.")
              : setFlipCardModalOpen(true);
          }}
        >
          <IcPatternHex className="w-full h-full absolute top-0 left-0 text-white" />
          {!isChallengeCompleted(challenges, "CH1") && isChallengeExpired(challenges, "CH1") ? (
            <div className="flex items-center justify-center w-full h-full">
              <img src="/assets/penomo_white_logo.png" className="w-4 h-4" alt="white-penomo-logo" />
            </div>
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              {isQuestLocked("CH1", false) ? (
                <IcLock className={"text-black"} />
              ) : (
                <>
                  {isChallengeCompleted(challenges, "CH1") ? (
                    <IcChecked className={"text-black"} />
                  ) : remainingTime ? (
                    <RemainingTimeCountdown remainingTime={remainingTime} />
                  ) : (
                    <>
                      <IcWhiteLockUnlocked className={"text-black"} />
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* RW2 */}
      <FlipCard flipCardClass="bottom-[-50.5%] right-[-74.5%]" flipCardId="RW2" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* RW3 - Red Card */}
      <FlipCard
        flipCardClass=""
        isShadowRed={isFlipCardClaimed(flipCards, "RW3") ? true : false}
        flipCardId="RW3"
        refetch={refetch}
        refetchStatistics={refetchStatistics}
        setLoading={setLoading}
      />

      {/* RW5 */}
      <FlipCard flipCardClass="top-[-50.3%] right-[-74.8%]" flipCardId="RW5" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* RW4 - Bonus */}
      <FlipCard
        flipCardClass="top-[-100.4%]"
        isShadowRed={isBonusCardDestroyed(bonusFlipCards, "RW4", flipCards) ? true : false}
        flipCardId="RW4"
        refetch={refetch}
        isBonusCard
        refetchStatistics={refetchStatistics}
        setLoading={setLoading}
      />

      {/* RW6 - Bonus */}
      <FlipCard
        flipCardClass="top-[-150.8%] right-[-74.8%]"
        isShadowRed={isBonusCardDestroyed(bonusFlipCards, "RW6", flipCards) ? true : false}
        flipCardId="RW6"
        refetch={refetch}
        isBonusCard
        refetchStatistics={refetchStatistics}
        setLoading={setLoading}
      />
    </div>
  );
};

export default ChallengeRow1;
