import React from 'react'

const IcLock = (props: any) => {
    return (
        <div {...props}>
            <svg width="16.1" height="17.5" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 12.3681C0 11.3631 0.399247 10.3992 1.10991 9.68852C1.82058 8.97786 2.78444 8.57861 3.78947 8.57861H18.9474C19.9524 8.57861 20.9163 8.97786 21.6269 9.68852C22.3376 10.3992 22.7368 11.3631 22.7368 12.3681V21.2102C22.7368 22.2152 22.3376 23.1791 21.6269 23.8898C20.9163 24.6004 19.9524 24.9997 18.9474 24.9997H3.78947C2.78444 24.9997 1.82058 24.6004 1.10991 23.8898C0.399247 23.1791 0 22.2152 0 21.2102L0 12.3681ZM12.6316 14.8944C12.6316 14.5594 12.4985 14.2381 12.2616 14.0012C12.0247 13.7643 11.7034 13.6312 11.3684 13.6312C11.0334 13.6312 10.7121 13.7643 10.4752 14.0012C10.2383 14.2381 10.1053 14.5594 10.1053 14.8944V18.6839C10.1053 19.0189 10.2383 19.3402 10.4752 19.5771C10.7121 19.814 11.0334 19.947 11.3684 19.947C11.7034 19.947 12.0247 19.814 12.2616 19.5771C12.4985 19.3402 12.6316 19.0189 12.6316 18.6839V14.8944Z" fill="currentColor" />
                <path d="M6.31641 9.84211V6.05263C6.31641 4.71259 6.84874 3.42743 7.79629 2.47988C8.74384 1.53233 10.029 1 11.369 1C12.7091 1 13.9942 1.53233 14.9418 2.47988C15.8893 3.42743 16.4217 4.71259 16.4217 6.05263V9.84211" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>

    )
}

export default IcLock