import useFlipCardStore from "@/store/useFlipCardStore";
import { isBonusCardDestroyed, isFlipCardClaimed } from "./ChallengeService";
import FlipCard from "./Reward";

const ChallengeRow10 = ({ isQuestLocked, setFlipCardModalOpen, refetch, refetchStatistics, setDetailsToShowInModal, setLoading }: any) => {
  const { challenges, flipCards, bonusFlipCards } = useFlipCardStore();
  return (
    <div className="grid relative">
      {/* RW52 - Bonus */}
      <FlipCard
        flipCardClass="bottom-[-150%] right-[-75%]"
        isShadowRed={isBonusCardDestroyed(bonusFlipCards, "RW52", flipCards) ? true : false}
        flipCardId="RW52"
        refetch={refetch} refetchStatistics={refetchStatistics}
        isBonusCard
        setLoading={setLoading}
      />

      {/* RW49 - Bonus */}
      <FlipCard
        flipCardClass="bottom-[-100.5%]"
        isShadowRed={isBonusCardDestroyed(bonusFlipCards, "RW49", flipCards) ? true : false}
        flipCardId="RW49"
        refetch={refetch} refetchStatistics={refetchStatistics}
        isBonusCard
        setLoading={setLoading}
      />

      {/* RW53 - Red Card */}
      <FlipCard flipCardClass="bottom-[-50%] right-[-75%]" isShadowRed={isFlipCardClaimed(flipCards, "RW53") ? true : false} flipCardId="RW53" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* RW50 */}
      <FlipCard flipCardClass="" flipCardId="RW50" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* RW54 */}
      <FlipCard flipCardClass="top-[-50%] right-[-75%]" flipCardId="RW54" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* RW51 */}
      <FlipCard flipCardClass="top-[-100.5%]" flipCardId="RW51" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* RW55 - Bonus */}
      <FlipCard
        flipCardClass="top-[-150%] right-[-75%]"
        isShadowRed={isBonusCardDestroyed(bonusFlipCards, "RW55", flipCards) ? true : false}
        flipCardId="RW55"
        refetch={refetch} refetchStatistics={refetchStatistics}
        isBonusCard
        setLoading={setLoading}
      />
    </div>
  );
};

export default ChallengeRow10;
