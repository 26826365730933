import { useEffect, useReducer, useRef, useState } from "react";
import { claimFlipCardService } from "./ChallengeService";
import { IcLock, IcPatternHex, IcRedCardInnerShadow, IcSkull, IcWhiteLockUnlocked } from "@/assets";
import useFlipCardStore from "@/store/useFlipCardStore";
import LogoLoader from "@/components/LogoLoader";

const FlipCardCard = ({ flipCardClass, flipCardId, refetch, isShadowRed = false, isBonusCard = false, refetchStatistics, setLoading }: any) => {
  const { challenges, flipCards, bonusFlipCards, revealBonusIds, setReavealBonusIds, shouldFlipGoldenCard, setFlipGoldenCard } = useFlipCardStore();

  const [thisFlipCard, setThisFlipCard] = useState({} as any);

  const [flipLOading, setFlipLoading] = useState(false);

  useEffect(() => {
    setThisFlipCard(
      isBonusCard
        ? bonusFlipCards?.find((item: any) => item.flipCardStaticId == flipCardId)
        : flipCards?.find((item: any) => item.flipCardStaticId == flipCardId)
    );
    setFlipLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flipCards, bonusFlipCards]);

  const innerCardRef = useRef<HTMLDivElement>(null);
  const flipCardPointsRef = useRef<HTMLDivElement>(null);
  const bonusCardContentRef = useRef<HTMLDivElement>(null);
  const onlyWhiteLogoContentRef = useRef<HTMLDivElement>(null);

  if (flipCardId == "RW3") {
    // console.log("🚀 ~ FlipCardCard ~ onlyWhiteLogoContentRef:", onlyWhiteLogoContentRef.current?.className);
    // console.log("thisFlipCard", flipCardId, thisFlipCard);
  }

  const challengesRef = useRef(challenges);

  useEffect(() => {
    // Update the ref whenever challenges change
    challengesRef.current = challenges;
  }, [challenges]);

  const flipOtherCards = () => {
    const isThisFlipCardARedCard = thisFlipCard?.isRedCard;

    if (isThisFlipCardARedCard) {
      const flipNextBonus = () => {
        let index = 0;
        const intervalId = setInterval(() => {
          const bonusFlipCardsOfThisFlipCard = bonusFlipCards.filter((item: any) => item.associatedFlipCardIds?.includes(thisFlipCard?.flipCardId));
          let bonusFlipCardOnlyStaticIds: any = bonusFlipCardsOfThisFlipCard.map((item: any) => item.flipCardStaticId);

          // get all challenges with which this flip card is associated
          const challengesOfThisFlipCard = challenges.filter((item: any) => item.flipCardIds?.includes(thisFlipCard?.flipCardId));

          for (let challenge of challengesOfThisFlipCard) {
            if (challenge.isChallengeCompleted) {
              bonusFlipCardOnlyStaticIds.push(
                ...challenge.flipCards.filter((item: any) => !item.isDestroyed && !item.isFlipCardClaimed).map((item: any) => item.flipCardStaticId)
              );
            }

            const a = challenge.flipCards?.forEach((card: any) => {
              if (card.isRedCard) {
                const bonusCardsOfTheseRedCards = bonusFlipCards?.filter(
                  (item: any) => !item.isDestroyed && !item.isFlipCardClaimed && item.associatedFlipCardIds?.includes(card.flipCardId)
                );

                if (bonusCardsOfTheseRedCards.length > 0) {
                  const newBonusFlipCardOnlyStaticIds = bonusCardsOfTheseRedCards
                    .map((item: any) => item.flipCardStaticId)
                    .filter((item) => !bonusFlipCardOnlyStaticIds.includes(item));

                  bonusFlipCardOnlyStaticIds.push(...newBonusFlipCardOnlyStaticIds);

                  const finalBonusFlipCardOnlyStaticIds = bonusFlipCardOnlyStaticIds.filter(
                    (item: any, index: any) =>
                      !bonusFlipCards?.find((card: any) => card.flipCardStaticId == item)?.isDestroyed &&
                      !bonusFlipCards?.find((card: any) => card.flipCardStaticId == item)?.isFlipCardClaimed
                  );

                  bonusFlipCardOnlyStaticIds = finalBonusFlipCardOnlyStaticIds;
                }
              }
            });
          }

          if (index >= bonusFlipCardOnlyStaticIds.length) {
            clearInterval(intervalId);
            return;
          }

          setReavealBonusIds(bonusFlipCardOnlyStaticIds[index] as string);

          index++;
        }, 50);
      };
      setTimeout(flipNextBonus, 100);
    } else {
      const flipNextBonus = () => {
        let index = 0;
        const intervalId = setInterval(() => {
          const bonusFlipCardOnlyStaticIds = [];

          // get all challenges with which this flip card is associated
          const challengesOfThisFlipCard = challengesRef.current.filter((item: any) => item.flipCardIds?.includes(thisFlipCard?.flipCardId));

          for (let challenge of challengesOfThisFlipCard) {
            if (challenge.isChallengeCompleted) {
              // check wheather all flip cards except red cards of this challenge are claimed
              const isAllFlipCardsExceptRedCardsClaimed = challenge.flipCards
                ?.filter((card: any) => !card.isRedCard)
                ?.every((card: any) => card.isFlipCardClaimed);

              if (isAllFlipCardsExceptRedCardsClaimed) {
                // get all red cards of this challenge
                const redCardsOfThisChallenge = challenge.flipCards?.filter((card: any) => card.isRedCard);

                for (let redCard of redCardsOfThisChallenge) {
                  bonusFlipCardOnlyStaticIds.push(redCard.flipCardStaticId);

                  // get bonus card associated with this red card
                  const bonusCardsOfTheseRedCards = bonusFlipCards?.filter((item: any) => item.associatedFlipCardIds?.includes(redCard.flipCardId));

                  bonusFlipCardOnlyStaticIds.push(
                    ...bonusCardsOfTheseRedCards.filter((item: any) => !item.isDestroyed && !item.isFlipCardClaimed).map((item: any) => item.flipCardStaticId)
                  );
                }
              }
            }
          }
          if (index >= bonusFlipCardOnlyStaticIds.length) {
            clearInterval(intervalId);
            return;
          }

          setReavealBonusIds(bonusFlipCardOnlyStaticIds[index] as string);

          index++;
        }, 50);
      };
      setTimeout(flipNextBonus, 100);
    }
  };

  const handleClickOfFlipCard = () => {
    if (thisFlipCard?.isFlipCardLocked) {
      return;
    }

    if (!thisFlipCard?.isFlipCardClaimed) {
      setLoading(true);
      setFlipLoading(true);
      innerCardRef.current?.classList.add("innner-content-flipped");
      claimFlipCardService(thisFlipCard?.flipCardId)
        .then(() => {
          setLoading(false);
          setTimeout((res: any) => {
            Promise.all([refetch(), refetchStatistics()]).then(() => {
              flipOtherCards();
            });

            // refetch();
            // refetchStatistics();
          }, 300);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    // if (shouldFlipGoldenCard && isBonusCard && !thisFlipCard?.isDestroyed && !thisFlipCard?.isFlipCardClaimed) {
    //   innerCardRef.current?.classList.add("innner-content-flipped");
    // }

    // here flipping those red cards which are not revealed earlier. isFlipCardRevealed field is used because we don't have isDestroyed field for red cards to differential them from each other, which are destroyed by user and which are not.
    // if (shouldFlipGoldenCard && thisFlipCard?.isRedCard && !thisFlipCard?.isExpired && !thisFlipCard?.isFlipCardRevealed) {
    //   innerCardRef.current?.classList.add("innner-content-flipped");
    //   setFlipGoldenCard(false);
    //   refetch();
    // }

    setTimeout(() => {
      if (!innerCardRef.current?.classList.contains("innner-content-flipped")) {
        flipCardPointsRef.current?.classList.remove("back-card");

        bonusCardContentRef.current?.classList.remove("flipped-text");
        onlyWhiteLogoContentRef.current?.classList.remove("flipped-text");
      }
    }, 700);
  }, [thisFlipCard, shouldFlipGoldenCard]);

  useEffect(() => {
    if (revealBonusIds === flipCardId) {
      // if (!thisFlipCard?.isDestroyed) {
      innerCardRef.current?.classList.add("innner-content-flipped");

      // Ensure the text is flipped as well when we are adding flipped class to parent
      if (!bonusCardContentRef.current?.classList.contains("flipped-text")) {
        bonusCardContentRef.current?.classList.add("flipped-text");
        refetchStatistics();
      }
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revealBonusIds]);

  return (
    <div className="w-[116.6px] relative aspect-[1/0.9]">
      <div className={`h-full relative parent w-full ${flipCardClass}`}>
        <div
          id={flipCardId}
          ref={innerCardRef}
          className={`h-full w-full flex justify-center items-center inner-content `}
          onClick={() => (isBonusCard || thisFlipCard?.isExpired || thisFlipCard?.isDestroyed ? " " : handleClickOfFlipCard())}
        >
          {!thisFlipCard?.isExpired || thisFlipCard?.isFlipCardClaimed ? (
            <>
              {" "}
              <IcRedCardInnerShadow
                isShadowRed={thisFlipCard?.isDestroyed || (thisFlipCard?.isRedCard && thisFlipCard?.isFlipCardClaimed) ? true : false}
                isShadowGolden={
                  isBonusCard && !thisFlipCard?.isDestroyed && thisFlipCard?.isFlipCardClaimed
                    ? true
                    : thisFlipCard?.isRedCard && !thisFlipCard?.isFlipCardRevealed && thisFlipCard?.isFlipCardClaimed && !thisFlipCard?.isExpired
                    ? true
                    : false
                }
                lightWhite
                className={`w-full h-full absolute top-0 left-0 text-monochrome-100`}
              />
              <IcLock
                className={`z-10 text-white ${
                  isBonusCard
                    ? thisFlipCard?.isDestroyed || thisFlipCard?.isFlipCardClaimed
                      ? "hidden"
                      : "block"
                    : thisFlipCard?.isFlipCardLocked
                    ? "block"
                    : "hidden"
                }`}
              />
              {/* Unlocked Icon */}
              {flipLOading && (
                <div className="min-h-[5px] flex align-center justify-center">
                  <LogoLoader isLoading={true} width="40px" height="40px" />
                </div>
              )}
              <div
                className={`unlockedLock absolute top-0 left-0 w-full h-full front-card ${
                  !thisFlipCard?.isFlipCardClaimed && !thisFlipCard?.isFlipCardLocked && !isBonusCard && !thisFlipCard?.isDestroyed ? "block" : "hidden"
                }`}
              >
                <IcWhiteLockUnlocked className="w-full h-full flex justify-center items-center " />
              </div>
              {/* Only White Logo */}
              <div
                ref={onlyWhiteLogoContentRef}
                className={`z-50 justify-center items-center flipped-text ${
                  !thisFlipCard?.isFlipCardLocked && thisFlipCard?.isRedCard && !thisFlipCard?.isFlipCardRevealed && thisFlipCard?.isFlipCardClaimed
                    ? "block"
                    : "hidden"
                }`}
              >
                <img src="/assets/penomo_white_logo.png" className="w-4 h-4" alt="white-penomo-logo" />
              </div>
              {/* Strike Through Points */}
              <div
                ref={bonusCardContentRef}
                className={`strikeThrough z-50 items-center gap-2 text-sm font-normal ${thisFlipCard?.isDestroyed ? "line-through" : ""} flipped-text ${
                  thisFlipCard?.isDestroyed
                    ? thisFlipCard?.isRedCard || !isBonusCard
                      ? "hidden"
                      : "flex"
                    : isBonusCard && thisFlipCard?.isFlipCardClaimed
                    ? "flex"
                    : "hidden"
                }`}
              >
                <img src="/assets/penomo_white_logo.png" className="w-4 h-4" alt="white-penomo-logo" />
                {thisFlipCard?.flipCardPoints + " XP"}
              </div>
              {/* Skull Or Points */}
              <div
                ref={flipCardPointsRef}
                className={`skullOrPoints absolute top-0 left-0 w-full h-full back-card ${
                  (thisFlipCard?.isFlipCardClaimed && !isBonusCard) || (thisFlipCard?.isDestroyed && !isBonusCard) ? "block" : "hidden"
                }`}
              >
                <div className={`w-full h-full flex items-center justify-center inner-shadow`}>
                  <IcSkull
                    className={`${
                      (thisFlipCard?.isRedCard && thisFlipCard?.isFlipCardRevealed) || (!isBonusCard && thisFlipCard?.isDestroyed) ? "block" : "hidden"
                    }`}
                  />
                  <div className={`z-50 items-center gap-2 text-sm ${thisFlipCard?.isRedCard || thisFlipCard?.isDestroyed ? "hidden" : "flex"}`}>
                    <img src="/assets/penomo_white_logo.png" className="w-4 h-4" alt="white-penomo-logo" />
                    {thisFlipCard?.flipCardPoints + " XP"}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="hexagon flex flex-col items-center justify-center">
              <IcRedCardInnerShadow isShadowGray lightWhite={false} className={`w-full h-full absolute top-0 left-0 text-monochrome-100`} />
              <IcPatternHex className="z-40 w-full h-full absolute top-0 left-0 text-white" />
              <img src="/assets/penomo_white_logo.png" className="w-4 h-4 z-50" alt="white-penomo-logo" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FlipCardCard;
