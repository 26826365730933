import useFlipCardStore from "@/store/useFlipCardStore";
import { isBonusCardDestroyed, isFlipCardClaimed } from "./ChallengeService";
import FlipCard from "./Reward";

const ChallengeRow7 = ({ isQuestLocked, setFlipCardModalOpen, refetch, refetchStatistics, setDetailsToShowInModal, setLoading }: any) => {
  const { challenges, flipCards, bonusFlipCards } = useFlipCardStore();
  return (
    <div className="grid relative">
      {/* RW36 - Bonus */}
      <FlipCard
        flipCardClass="bottom-[-150%] right-[-75%]"
        isShadowRed={isBonusCardDestroyed(bonusFlipCards, "RW36", flipCards) ? true : false}
        flipCardId="RW36"
        refetch={refetch} refetchStatistics={refetchStatistics}
        isBonusCard
        setLoading={setLoading}
      />

      {/* RW33 */}
      <FlipCard flipCardClass="bottom-[-100.5%] z-50" flipCardId="RW33" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* RW37 */}
      <FlipCard flipCardClass="bottom-[-50%] right-[-75%]" flipCardId="RW37" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* RW34 - Red Card */}
      <FlipCard flipCardClass="" isShadowRed={isFlipCardClaimed(flipCards, "RW34") ? true : false} flipCardId="RW34" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* RW38 */}
      <FlipCard flipCardClass="top-[-50%] right-[-75%]" flipCardId="RW38" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* RW35 - Bonus */}
      <FlipCard
        flipCardClass="top-[-100.5%]"
        isShadowRed={isBonusCardDestroyed(bonusFlipCards, "RW35", flipCards) ? true : false}
        flipCardId="RW35"
        refetch={refetch} refetchStatistics={refetchStatistics}
        isBonusCard
        setLoading={setLoading}
      />

      {/* RW39 - Bonus */}
      <FlipCard
        flipCardClass="top-[-150%] right-[-75%]"
        isShadowRed={isBonusCardDestroyed(bonusFlipCards, "RW39", flipCards) ? true : false}
        flipCardId="RW39"
        refetch={refetch} refetchStatistics={refetchStatistics}
        isBonusCard
        setLoading={setLoading}
      />
    </div>
  );
};

export default ChallengeRow7;
