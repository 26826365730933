const IcPatternHex = (props: any) => {
    return (
        <div {...props}>
            <svg width="165" height="145" viewBox="0 0 165 145" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_4122_947104)">
                    <path d="M-3.57259 36.6841L-3.57259 -3.18422L6.6416 -13.3984L6.6416 -47.6654" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <path d="M-13.4585 36.6841L-13.4585 -3.18422L-13.4585 -34.4858L-4.56224 -43.382L-4.56224 -79.6259L-4.56224 -122.789L5.98145 -133.333L5.98145 -168.588" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <path d="M-2.90918 113.126L-2.90918 152.995L6.64603 163.209L6.64603 197.476" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <path d="M3.73535 104.287L43.6036 104.287L53.8178 114.501H88.0848" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <rect width="9.8847" height="3.2949" transform="matrix(1 0 0 -1 3.73535 105.935)" fill="currentColor" fillOpacity="0.05" />
                    <circle cx="2.63592" cy="2.63592" r="2.63592" transform="matrix(1 0 0 -1 88.085 117.137)" fill="currentColor" fillOpacity="0.05" />
                    <path d="M3.73535 44.3198L43.6036 44.3198L53.8178 34.7646L88.0848 34.7646" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <rect width="9.8847" height="3.2949" transform="matrix(1 0 0 -1 3.73535 45.9668)" fill="currentColor" fillOpacity="0.05" />
                    <circle cx="2.63592" cy="2.63592" r="2.63592" transform="matrix(1 0 0 -1 88.085 37.3999)" fill="currentColor" fillOpacity="0.05" />
                    <path d="M3.73535 94.4024L43.6037 94.4024H74.9052L83.8015 103.299L120.045 103.299L163.209 103.299L173.752 113.842H209.008" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <rect width="9.8847" height="3.2949" transform="matrix(1 0 0 -1 3.73535 96.0503)" fill="currentColor" fillOpacity="0.05" />
                    <path d="M3.73535 84.5172L43.6037 84.5172H81.495L90.3912 93.4134L126.635 93.4134L169.798 93.4134H183.307L192.533 102.639L233.719 102.639" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <rect width="9.8847" height="3.2949" transform="matrix(1 0 0 -1 3.73535 86.165)" fill="currentColor" fillOpacity="0.05" />
                    <path d="M3.73535 74.6329L43.6037 74.6329L89.4028 74.6329L98.299 83.5292H139.815L182.978 83.5292H196.487L205.713 92.7549L246.899 92.7549" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <rect width="9.8847" height="3.2949" transform="matrix(1 0 0 -1 3.73535 76.2803)" fill="currentColor" fillOpacity="0.05" />
                    <path d="M3.73535 64.7488L43.6037 64.7488L97.9695 64.7488L106.866 73.645H139.815L182.978 73.645H202.418L211.644 67.7142L260.079 67.7142" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <rect width="9.8847" height="3.2949" transform="matrix(1 0 0 -1 3.73535 66.3965)" fill="currentColor" fillOpacity="0.05" />
                    <path d="M3.73535 54.8638L43.6037 54.8638L111.149 54.8638L116.091 49.9214L223.835 49.9214" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <rect width="9.8847" height="3.2949" transform="matrix(1 0 0 -1 3.73535 56.5107)" fill="currentColor" fillOpacity="0.05" />
                    <path d="M117.078 65.0781L170.785 65.0781L176.716 59.1473L206.699 59.1473" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <circle cx="2.63592" cy="2.63592" r="2.18791" transform="matrix(1 0 0 -1 112.466 67.7139)" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <path d="M101.264 34.766L154.971 34.766L160.901 39.3789L190.885 39.3789" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <circle cx="2.63592" cy="2.63592" r="2.18791" transform="matrix(1 0 0 -1 96.6511 37.4014)" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <path d="M66.8427 184.265L38.6515 156.074L38.6515 141.629L14.4211 117.399" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <circle cx="2.63592" cy="2.63592" r="2.63592" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 16.2859 115.535)" fill="currentColor" fillOpacity="0.05" />
                    <path d="M140.073 234.396L140.073 221.815L116.774 198.516L86.2532 167.995L72.5071 154.249L61.7898 151.919L27.5411 117.671" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <circle cx="2.63592" cy="2.63592" r="2.63592" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 30.8025 117.205)" fill="currentColor" fillOpacity="0.05" />
                    <path d="M137.907 194.142L99.9305 156.166L91.543 156.166L70.3414 134.964" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <circle cx="2.63592" cy="2.63592" r="2.18791" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 72.6707 133.565)" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <path d="M138.417 126.303L84.7099 126.303L78.779 121.69L48.7954 121.69" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <circle cx="2.63592" cy="2.63592" r="2.18791" transform="matrix(-1 8.74228e-08 8.74228e-08 1 143.029 123.667)" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <circle cx="2.63592" cy="2.63592" r="2.18791" transform="matrix(-1 8.74228e-08 8.74228e-08 1 49.4551 119.055)" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <circle cx="2.63592" cy="2.63592" r="2.18791" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 12.2839 148.483)" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <path d="M72.039 200.697L34.0625 162.721L33.1306 155.265L11.9289 134.064" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <circle cx="2.63592" cy="2.63592" r="2.18791" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 14.2598 132.666)" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <path d="M66.84 -35.4789L38.6488 -7.28773L38.6488 7.15732L14.4184 31.3877" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <circle cx="12.5545" cy="33.2515" r="2.63592" transform="rotate(135 12.5545 33.2515)" fill="currentColor" fillOpacity="0.05" />
                    <path d="M140.07 -85.6104L140.07 -73.0292L116.772 -49.7308L86.2505 -19.2098L72.5044 -5.46369L61.7872 -3.13384L27.5384 31.1149" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <circle cx="27.0721" cy="31.5806" r="2.63592" transform="rotate(135 27.0721 31.5806)" fill="currentColor" fillOpacity="0.05" />
                    <path d="M137.905 -45.356L99.928 -7.37946L91.5406 -7.37946L70.339 13.8221" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <circle cx="68.9402" cy="15.2202" r="2.18791" transform="rotate(135 68.9402 15.2202)" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <path d="M138.416 22.4824L84.7091 22.4823L78.7783 27.0952L48.7947 27.0952" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <circle cx="140.392" cy="22.4818" r="2.18791" transform="rotate(-180 140.392 22.4818)" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <circle cx="46.8172" cy="27.0945" r="2.18791" transform="rotate(-180 46.8172 27.0945)" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <circle cx="8.55423" cy="0.303711" r="2.18791" transform="rotate(135 8.55423 0.303711)" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <path d="M72.0388 -51.9132L34.0622 -13.9367L33.1303 -6.48114L11.9287 14.7205" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <circle cx="10.5308" cy="16.1187" r="2.18791" transform="rotate(135 10.5308 16.1187)" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                    <path d="M159.683 -47.2711L197.659 -9.29456L198.591 -1.83905L219.793 19.3626" stroke="currentColor" strokeOpacity="0.05" strokeWidth="0.896025" />
                </g>
                <defs>
                    <clipPath id="clip0_4122_947104">
                        <rect width="211.462" height="239.669" fill="currentColor" transform="translate(-46.8372 -47.7285)" />
                    </clipPath>
                </defs>
            </svg>

        </div>
    )
}

export default IcPatternHex;