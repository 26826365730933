import useAuth from "@/hooks/useAuth";
import { formatNumber } from "@/utils";
interface Iprops {
  item: any;
}
const LeaderboardItem = ({ item }: Iprops) => {
  const { userId } = useAuth();

  function shortenAddress(address: string | null) {
    if (!address || address.length < 10) {
      return address;
    }
    const start = address.slice(0, 5);
    const end = address.slice(-5);
    return `${start}….${end}`;
  }

  return (
    <div className="flex p-4 items-center text-white border-b border-green-30">
      <div className="w-32">
        {item.rank} {item.userId == userId && <span className="ml-4 bg-white rounded-full text-monochrome-100 px-2">You</span>}
      </div>
      <div className="w-5/12 flex flex-col gap-1">
        <span>{shortenAddress(item.walletAddress)}</span>
      </div>
      <span className="w-3/12">{formatNumber(item.totalClaimedFlipCardPoints)} XP</span>
      {/* <span className='w-3/12'>{item.completedQuestCount}</span> */}
    </div>
  );
};

export default LeaderboardItem;
