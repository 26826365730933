import ChallengeRow3 from "./ChallengeRow3";
import ChallengeRow2 from "./ChallengeRow2";
import ChallengeRow1 from "./ChallengeRow1";

const Tree1 = ({ remainingTime, isQuestLocked, setFlipCardModalOpen, refetchStatistics, refetch, setDetailsToShowInModal, setLoading }: any) => {
  const rows = [ChallengeRow1, ChallengeRow2, ChallengeRow3];

  return (
    <>
      <div className="relative flex-none">
        {/* <div className="w-[192.5px] absolute left-16 top-1/2 -translate-y-1/2 aspect-[1/0.9]">
          <div
            className="bg-[#D4AF37] z-50 hexagon bottom-0 flex flex-col items-center justify-center text-base cursor-pointer mt-6"
            onClick={() => {
              setFlipCardModalOpen(true);
              setDetailsToShowInModal("referral");
            }}
          >
            <img src="/assets/penomo_white_logo.png" className="w-6 h-6 mx-auto mb-2" alt="white-penomo-logo" />
            Referral Quest
          </div>
        </div> */}

        <div className="flex gap-[58px] ml-[100px] mb-[-26.5px]">
          {rows.map((Row, index) => {
            return (
              <Row
                key={index}
                remainingTime={remainingTime}
                isQuestLocked={isQuestLocked}
                setFlipCardModalOpen={setFlipCardModalOpen}
                refetchStatistics={refetchStatistics}
                refetch={refetch}
                setDetailsToShowInModal={setDetailsToShowInModal}
                setLoading={setLoading}
              />
            );
          })}
        </div>
        <img src="/assets/stand2.png" className="w-[500px] absolute z-0 bottom-[-51px] left-[75px] object-cover" alt="grass" />
      </div>
    </>
  );
};

export default Tree1;
