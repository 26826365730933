import "./quest.css";
import IcCross from "@/assets/ic_cross";
import { useQuery } from "@tanstack/react-query";
import {
  checkTelegramUserJoined,
  discordCallback,
  fetchUser,
  followTwitter,
  getChallenges,
  getDiscordAuthURL,
  getQuestStatistics,
  getTelegramAuthURL,
  getTwitterAuthURL,
  joinDiscord,
  retweetTwitter,
  telegramCallback,
  twitterCallback,
} from "@/services/api";
import { useEffect, useRef, useState } from "react";
import { Modal } from "flowbite-react";
import Button from "@/components/UI/Button";
import { useSearchParams } from "react-router-dom";
import { getChallengeByChallengeStaticId } from "./ChallengeRows/ChallengeService";
import Tree1 from "./ChallengeRows/Tree1";
import Tree2 from "./ChallengeRows/Tree2";
import Tree3 from "./ChallengeRows/Tree3";
import Tree4 from "./ChallengeRows/Tree4";

import { IcCopy } from "@/assets";
import toast from "react-hot-toast";
import useFlipCardStore from "@/store/useFlipCardStore";
import { IcRedirect } from "@/assets/menu";
import useAuth from "@/hooks/useAuth";
import ClaimReaqRewardModal from "../Dashboard/Dialogs/ModalClaimReaqReward";
import LogoLoader from "@/components/LogoLoader";

const Quest = () => {
  const { userId } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const callbackCode: any = searchParams.get("code");

  const telegramHash = window.location.hash.substring(1);
  const telegramParam = new URLSearchParams(telegramHash);
  const tgAuthResult: any = telegramParam.get("tgAuthResult");

  const isApiCalled = useRef(false);
  const [isFlipCardModalOpen, setFlipCardModalOpen] = useState(false);
  const [detailsToShowInModal, setDetailsToShowInModal] = useState<any>("challenge");
  const [peaqRewardModal, setPeaqRewardModal] = useState(false);
  const [unlockNextChallengeAt, setUnlockNextChallengeAt] = useState<any>(localStorage.getItem("unlockNextChallengeAt") || null);
  const [remainingTime, setRemainingTime] = useState<number | null>(null);

  const [selectedChallenge, setSelectedChallenge] = useState<any>(null);
  const [showTelegramChannelJoinModal, setShowTelegramChannelJoinModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const {
    data: questStatistics,
    isLoading: isLoadingQuestStatistics,
    refetch: refetchStatistics,
  } = useQuery({
    queryKey: ["getQuestStatistics"],
    queryFn: () => getQuestStatistics(),
    select: (data: any) => data.data,
  });

  const {
    data: challengesData,
    isLoading: isLoadingChallenges,
    refetch,
  } = useQuery({
    queryKey: ["getChallenges"],
    queryFn: () => getChallenges(),
    select: (data: any) => data.data,
  });

  const {
    data: userData,
    isLoading: isLoadingUser,
    refetch: refetchUserData,
  } = useQuery({
    queryKey: ["getUser", userId],
    queryFn: () => fetchUser(userId),
    enabled: !!userId,
    select: (data) => data.data,
  });

  const { challenges, setChallenges, setFlipCards, setbonusFlipCards } = useFlipCardStore();

  useEffect(() => {
    const selectedChallenge = localStorage.getItem("selectedChallenge");

    if (selectedChallenge === "CH4") {
      setSelectedChallenge(challenges.find((challenge: any) => challenge.challengeStaticId === "CH4"));
    }
  }, [challenges]);

  useEffect(() => {
    if (unlockNextChallengeAt && remainingTime === null) {
      // if we have remainingTime null set it to time remaining based on unlockNextChallengeAt
      setRemainingTime(Math.floor((Number(unlockNextChallengeAt) - new Date().getTime()) / 1000) || 60);
    }
    if (unlockNextChallengeAt && remainingTime !== null) {
      if (remainingTime && remainingTime > 0) {
        // remaining time is more than 0 than decrement to show timer on the each challenge
        const interval = setInterval(() => {
          setRemainingTime(remainingTime - 1);
        }, 1000);

        return () => clearInterval(interval);
      } else {
        // remaining time has reached to 0 so unlock all challenges by setting both fields to null and rmove value from localstorage
        setRemainingTime(null);
        setUnlockNextChallengeAt(null);
        localStorage.removeItem("unlockNextChallengeAt");
      }
    }
  }, [unlockNextChallengeAt, remainingTime]);

  useEffect(() => {
    if (challengesData && challengesData.challengeDetails && challengesData.challengeDetails.length > 0) {
      let allFlipCards: any = [];
      challengesData.challengeDetails.map((challenge: any) => {
        if (challenge.flipCards) {
          allFlipCards.push(...challenge.flipCards);
        }
      });

      const filteredFlipCards = allFlipCards.reduce((acc: any, current: any) => {
        // Check if the flipCardStaticId already exists in the accumulator
        const existingFlipCard = acc.find((flipCard: any) => flipCard.flipCardStaticId === current.flipCardStaticId);

        if (existingFlipCard) {
          // If it exists, check if the current flipCard is claimed and replace the existing one
          if (current.isFlipCardClaimed && !existingFlipCard.isFlipCardClaimed) {
            return acc.map((flipCard: any) => (flipCard.flipCardStaticId === current.flipCardStaticId ? current : flipCard));
          }

          // check if the current flipCard is destroyed and replace the existing one
          if (current.isDestroyed && !existingFlipCard.isDestroyed) {
            return acc.map((flipCard: any) => (flipCard.flipCardStaticId === current.flipCardStaticId ? current : flipCard));
          }

          // Otherwise, keep the existing flipCard
          return acc;
        }

        // If it doesn't exist, add the current flipCard to the accumulator
        return [...acc, current];
      }, []);

      setFlipCards(filteredFlipCards);

      setChallenges(challengesData.challengeDetails);

      setbonusFlipCards(challengesData.allBonusCards);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challengesData]);

  const isQuestLocked = (staticChallengeId: string, save: boolean) => {
    const challenge = challenges.find((challenge: any) => challenge.challengeStaticId === staticChallengeId);
    const isLocked = challenge?.isLocked ?? false;
    if (save) {
      setSelectedChallenge(challenge);
    }
    return isLocked;
  };

  const handleModalButtonClick = () => {
    const challenge = getChallengeByChallengeStaticId(challenges, selectedChallenge.challengeStaticId);
    localStorage.setItem("challengePlatform", challenge.challengePlatform);

    if (challenge.challengePlatform === "twitter") {
      handleTwitterClickForFollow();
    } else if (challenge.challengePlatform === "discord") {
      handleDiscordClickForFollow();
    } else if (challenge.challengePlatform === "telegram") {
      handleTelegramClickForFollow();
    }
  };

  // TWITTER
  const handleTwitterClickForFollow = () => {
    setLoading(true);
    getTwitterAuthURL()
      .then((res: any) => {
        if (res.code === 200) {
          localStorage.setItem("selectedChallenge", selectedChallenge.challengeStaticId);
          window.location.href = res.data;
        }
      })
      .catch((err: any) => {
        // console.log("err: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTwitterCallback = (code: string) => {
    setSearchParams({});
    setLoading(true);
    twitterCallback(code)
      .then((res: any) => {
        if (res.code === 200) {
          let challengeStaticId = localStorage.getItem("selectedChallenge");
          if (challengeStaticId) {
            const challenge = getChallengeByChallengeStaticId(challenges, challengeStaticId);
            localStorage.removeItem("selectedChallenge");

            switch (challenge.challengeType) {
              case "follow":
                makeFollowRequest(challenge._id);
                break;
              case "retweet":
                makeRetweetRequest(challenge._id);
                break;
              default:
                setLoading(false);
                break;
            }
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      })
      .catch((err: any) => {
        // console.log("err: ", err);
        setLoading(false);
      })
      .finally(() => {
        isApiCalled.current = false;
      });
  };

  const updateLastChallengeCompletedTime = () => {
    // // Based on current timestamp add 5,000 miliseconds to it to get timestamp when to unlock next challenges
    // const currentTimeStamp = new Date().getTime() + 5_000;
    // setUnlockNextChallengeAt(currentTimeStamp);
    // localStorage.setItem("unlockNextChallengeAt", `${currentTimeStamp}`);
  };

  const makeFollowRequest = (challengeId: any) => {
    setLoading(true);
    followTwitter(challengeId)
      .then((res: any) => {
        if (res.code === 200) {
          refetch();
          refetchStatistics();
          updateLastChallengeCompletedTime();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const makeRetweetRequest = (challengeId: any) => {
    setLoading(true);
    retweetTwitter(challengeId)
      .then((res: any) => {
        if (res.code === 200) {
          refetch();
          refetchStatistics();
          updateLastChallengeCompletedTime();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // DISCORD
  const handleDiscordClickForFollow = () => {
    setLoading(true);
    getDiscordAuthURL()
      .then((res: any) => {
        if (res.code === 200) {
          localStorage.setItem("selectedChallenge", selectedChallenge.challengeStaticId);
          window.location.href = res.data;
        }
      })
      .catch((err: any) => {
        // console.log("err: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDiscordCallback = (code: string) => {
    setSearchParams({});
    setLoading(true);
    discordCallback(code)
      .then((res: any) => {
        if (res.code === 200) {
          let challengeStaticId = localStorage.getItem("selectedChallenge");
          if (challengeStaticId) {
            const challenge = getChallengeByChallengeStaticId(challenges, challengeStaticId);
            localStorage.removeItem("selectedChallenge");

            switch (challenge.challengeType) {
              case "join":
                makeJoinRequest(challenge._id);
                break;
              default:
                setLoading(false);
                break;
            }
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      })
      .catch((err: any) => {
        // console.log("err: ", err);
        setLoading(false);
      })
      .finally(() => {
        isApiCalled.current = false;
      });
  };

  const makeJoinRequest = (challengeId: any) => {
    setLoading(true);
    joinDiscord(challengeId)
      .then((res: any) => {
        if (res.code === 200) {
          refetch();
          refetchStatistics();
          updateLastChallengeCompletedTime();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // TELEGRAM
  const handleTelegramClickForFollow = () => {
    setLoading(true);
    getTelegramAuthURL()
      .then((res: any) => {
        if (res.code === 200) {
          localStorage.setItem("selectedChallenge", selectedChallenge.challengeStaticId);
          window.location.href = res.data;
        }
      })
      .catch((err: any) => {
        // console.log("err: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTelegramCallback = (code: string) => {
    window.history.replaceState("", "", window.location.pathname);
    setLoading(true);
    telegramCallback(code)
      .then((res: any) => {
        if (res.code === 200 && res.data.userInfo.id) {
          setShowTelegramChannelJoinModal(true);
        }
      })
      .catch((err: any) => {
        // console.log("err: ", err);
      })
      .finally(() => {
        isApiCalled.current = false;
        setLoading(false);
      });
  };

  const checkWheatherUserJoinedTelegramChannel = () => {
    setLoading(true);
    let challengeStaticId: any = localStorage.getItem("selectedChallenge");
    const challenge = getChallengeByChallengeStaticId(challenges, challengeStaticId);

    checkTelegramUserJoined(challenge._id)
      .then((res: any) => {
        if (res.code === 200 && res.data.hasUserJoinedChannel) {
          setShowTelegramChannelJoinModal(false);
          refetch();
          updateLastChallengeCompletedTime();
          localStorage.removeItem("selectedChallenge");
        } else {
          toast.error("You still haven't joined the channel. Please join it first.");
        }
      })
      .catch((err: any) => {
        // console.log("err: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      (callbackCode && isApiCalled.current === false && challenges && challenges.length > 0) ||
      (tgAuthResult && isApiCalled.current === false && challenges && challenges.length > 0)
    ) {
      isApiCalled.current = true;

      const challengePlatform = localStorage.getItem("challengePlatform");

      if (challengePlatform === "twitter") {
        handleTwitterCallback(callbackCode);
      } else if (challengePlatform === "discord") {
        handleDiscordCallback(callbackCode);
      } else if (challengePlatform === "telegram") {
        handleTelegramCallback(tgAuthResult);
      }

      localStorage.removeItem("challengePlatform");

      window.scrollTo(0, document.body.scrollHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackCode, tgAuthResult, challenges]);

  const referralLink = process.env.REACT_APP_FRONTEND_URL_LOCAL + "?ref=" + userData?.referral_code;

  const handleOnCopy = () => {
    navigator.clipboard.writeText(referralLink);
    toast.success("Referral code copied successfully");
  };

  const rows = [Tree1, Tree2, Tree3, Tree4];

  return (
    <>
      {/* <section className="mx-3 px-4 border-b border-l border-r border-green-30 relative overflow-hidden flex justify-between items-center">
        <div>
          <h3>You&apos;ve Successfully Unlocked 4 Free FlipCard!</h3>
          <span className="text-green-500">Get free flipCards by completing green card quests and unlocking more black cards. Thanks for following us on X!</span>
        </div>
        <div className="flex items-center">
          <img src="/assets/dashboard/trophy.png" alt="trophy" className="" />
          <IcCross />
        </div>
      </section> */}
      <section className="w-full px-3">
        <div className="grid sm:grid-cols-3 border-b border-green-30 w-full">
          <div className="p-4 border-l border-r md:border-r-0 border-green-30">
            <div className="p-4 rounded-lg border border-green-30 h-full">
              <div className="flex gap-1 flex-col body-medium">
                <span className="uppercase text-green-500">ACTIVE QUESTS</span>
                <span className="text-2xl font-bold">{questStatistics?.activeQuest}</span>
              </div>
            </div>
          </div>
          <div className="p-4 border-l border-r border-green-30">
            <div className="p-4 flex flex-col gap-10 rounded-lg border border-green-30 h-full">
              <div className="flex gap-1 flex-col body-medium">
                <span className="uppercase text-green-500">QUESTS COMPLETED</span>
                <span className="text-2xl font-bold">{questStatistics?.questCompleted}</span>
              </div>
              {/* <div className="bg-green-10 w-fit flex gap-2 items-center px-4 py-2 rounded-full text-green-500">
                <IcQuestRemain fillcolor="#1A1C22" />
                <span className="text-white">out of {questStatistics?.totalQuest} Quest</span>
              </div> */}
            </div>
          </div>
          <div className="p-4 border-r border-l md:border-l-0 border-green-30 ">
            <div className="p-4 flex flex-col gap-10 rounded-lg border border-green-30 h-full">
              <div className="flex gap-1 flex-col body-medium">
                <span className="uppercase text-green-500">CLAIMED XP</span>
                <span className="text-2xl font-bold">{questStatistics?.claimedPoint || 0} XP</span>
              </div>
              {/* <div className="bg-white w-fit flex gap-2 items-center px-4 py-2 rounded-full text-monochrome-100">
                <IcQuestRemain fillcolor="#fff" />
                out of {questStatistics?.totalFlipCardPoints} XP
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="mx-3 p-4 flex flex-col border-l border-r border-b border-green-30 text-green-500">
        <span>PROGRESS</span>
        <div className="mt-4 mb-1 h-2 bg-green-30 rounded">
          <div className={`h-full bg-green-500 rounded`} style={{ width: `${questStatistics?.completionPercentage}%` }}></div>
        </div>
        <div className="flex justify-between text-white relative">
          <span></span>
          <span>100%</span>
          <div
            className={`mt-1 absolute ${questStatistics?.completionPercentage === 100 ? "right-0" : ""} ${
              questStatistics?.completionPercentage > 0 && questStatistics?.completionPercentage < 100 && "-translate-x-1/2"
            }`}
            style={{
              left: questStatistics?.completionPercentage !== 100 ? `${questStatistics?.completionPercentage}%` : "auto",
            }}
          >
            <span className="font-semibold py-0.5 text-monochrome-100 bg-green-500 px-1 rounded">{Math.ceil(questStatistics?.completionPercentage)} %</span>
          </div>
        </div>
      </section>

      <section className="w-screen lg:w-[calc(100vw-300px)] relative pt-1">
        <img src="/assets/pattern.png" className="z-0 w-full absolute top-1 h-full object-cover" alt="grass" />
        <div
          className="z-0 absolute top-0 left-0 w-full h-full opacity-30"
          style={{ background: "linear-gradient(rgba(1, 210, 164, 0), rgba(1, 210, 164, 0.4))" }}
        ></div>
        <div className="relative z-50 w-full overflow-x-scroll overflow-y-hidden">
          <div className="z-0 flex gap-[1.5px] w-fit">
            {rows.map((Row, index) => {
              return (
                <Row
                  key={index}
                  isQuestLocked={isQuestLocked}
                  remainingTime={remainingTime}
                  setFlipCardModalOpen={setFlipCardModalOpen}
                  refetchStatistics={refetchStatistics}
                  refetch={refetch}
                  setDetailsToShowInModal={setDetailsToShowInModal}
                  setPeaqRewardModal={setPeaqRewardModal}
                  setLoading={setLoading}
                />
              );
            })}
          </div>
        </div>
        <div
          className="z-10 absolute bottom-0 left-0 w-full h-[100px]"
          style={{ background: "linear-gradient(rgba(28, 36, 38, 1), rgba(47, 71, 62, 1))" }}
        ></div>
      </section>

      {(isLoadingUser || isLoadingChallenges || loading || isLoadingQuestStatistics) && (
        <div className="min-h-[40px] flex align-center justify-center">
          <LogoLoader isLoading={true} />
        </div>
      )}
      <Modal
        show={isFlipCardModalOpen}
        onClose={() => {
          setFlipCardModalOpen(false);
        }}
        position={"center"}
        size={"2xl"}
      >
        <Modal.Body className="p-6 bg-monochrome-100 rounded-lg">
          <div className={"flex justify-between items-center pb-5"}>
            <h2 className="mb-0 font-semibold">Quest</h2>
            <Button
              classNames=" bg-monochrome-60 border border-monochrome-40 !p-1.5 !rounded-xl"
              rounded
              onClick={() => {
                setFlipCardModalOpen(false);
                setDetailsToShowInModal("challenge");
              }}
            >
              <IcCross />
            </Button>
          </div>
          <hr className="border-monochrome-40" />

          {detailsToShowInModal === "challenge" && (
            <section className="pt-5">
              {challenges.length <= 0 ? (
                <div className="flex align-center justify-center">
                  <LogoLoader isLoading={true} width="50px" height="50px" isForModel="true" />
                </div>
              ) : (
                <>
                  <p className="text-base">{selectedChallenge?.description}</p>
                  <Button secondary classNames="rounded-lg text-black text-sm w-full mt-4" onClick={handleModalButtonClick}>
                    {selectedChallenge?.title}
                  </Button>
                </>
              )}
            </section>
          )}

          {detailsToShowInModal === "referral" && (
            <section className="pt-5">
              <p className="text-base">Send this referral code to your friends and get rewarded 1000XP upon their sign up</p>

              <div className="flex items-center justify-center gap-4 bg-monochrome-40 w-[350px] h-[50px] mx-auto mt-4">
                <p className="text-xs sm:text-sm truncate">{referralLink}</p>
                <span className="cursor-pointer" onClick={handleOnCopy}>
                  <IcCopy />
                </span>
              </div>
            </section>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showTelegramChannelJoinModal}
        onClose={() => {
          setShowTelegramChannelJoinModal(false);
        }}
        position={"center"}
        size={"2xl"}
      >
        <Modal.Body className="p-6 bg-monochrome-100 rounded-lg">
          <div className={"flex justify-between items-center pb-5"}>
            <h2 className="mb-0 font-semibold">You are about to leave penomo.io</h2>
            <Button
              classNames=" bg-monochrome-60 border border-monochrome-40 !p-1.5 !rounded-xl"
              rounded
              onClick={() => {
                setShowTelegramChannelJoinModal(false);
                setDetailsToShowInModal("challenge");
              }}
            >
              <IcCross />
            </Button>
          </div>
          <hr className="border-monochrome-40" />

          <div className="mt-4">
            <p className="text-base">Redirection to external link:</p>
            <a className="text-sm text-green-500">{selectedChallenge?.pageLink}</a>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <Button secondary classNames="rounded-lg text-black text-sm w-full mt-4" onClick={() => window.open("https://t.me/+srjc6SinnudhNmU1")}>
              Click Here to Join Channel <IcRedirect className={"text-black"} />
            </Button>
            <Button secondary classNames="rounded-lg text-black text-sm w-full mt-4" onClick={checkWheatherUserJoinedTelegramChannel}>
              {loading ? "Checking..." : "Check"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {peaqRewardModal && (
        <ClaimReaqRewardModal
          handleClose={() => setPeaqRewardModal(false)}
          user={userData}
          challengeId={selectedChallenge?._id}
          refetchChallenges={refetch}
          refetchStatistics={refetchStatistics}
        />
      )}
    </>
  );
};

export default Quest;
