import { IcNextPage, IcParticipants, IcPreviousPage, IcQuestRemain } from "@/assets";
import LeaderboardItem from "./LeaderboardItem";
import { useState } from "react";
import { getLeaderboardMetrices, getLeaderboardRankings } from "@/services/api";
import { useQuery } from "@tanstack/react-query";
import LogoLoader from "@/components/LogoLoader";

const perPageItems = [10, 25, 50, 100];

const Leaderboard = () => {
  const [perPageCount, setPerPageCount] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const totalPages = 10;

  const { data: leaderboardMetricesData, isLoading: isLoadingLeaderboardMetrices } = useQuery({
    queryKey: ["getLeaderboardMetrices"],
    queryFn: () => getLeaderboardMetrices(),
    select: (data) => data.data,
  });

  const { data: leaderboardRankingsData, isLoading: isLoadingLeaderboardRankings } = useQuery({
    queryKey: ["getLeaderboardRankings"],
    queryFn: () => getLeaderboardRankings(currentPage, perPageCount),
    select: (data) => data.data,
  });

  return (
    <>
      <section className="w-full px-3">
        {(isLoadingLeaderboardRankings || isLoadingLeaderboardMetrices) && (
          <div className="min-h-[40px] flex align-center justify-center">
            <LogoLoader isLoading={true} />
          </div>
        )}
        <div className="grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 border-b border-green-30 w-full">
          <div className="p-4 border-l border-b md:border-b-0  border-green-30 border-r ">
            <div className="p-4 flex gap-10 flex-col rounded bg-green-500">
              <div className="flex gap-1 flex-col body-medium text-monochrome-100">
                <span className="uppercase">your rank</span>
                <span className="text-2xl font-bold">{leaderboardMetricesData?.yourRank}</span>
              </div>
              <div className="bg-monochrome-100 flex gap-2 justify-center px-4 py-2 body-medium rounded-full text-white">
                <IcParticipants className="text-green-500" />
                {leaderboardMetricesData?.totalParticipants} <span className="!text-monochrome-20">participants</span>
              </div>
            </div>
          </div>
          <div className="p-4 border-r border-b md:border-b-0 border-l md:border-l-0 border-green-30 ">
            <div className="p-4 rounded border border-green-30 h-full">
              <div className="flex gap-1 flex-col body-medium">
                <span className="uppercase text-green-500">your points</span>
                <span className="text-2xl font-bold">{leaderboardMetricesData?.yourpoint} XP</span>
              </div>
            </div>
          </div>
          {/* <div className='p-4 border-r border-l md:border-l-0 border-b md:border-b-0 border-green-30'>
                        <div className='p-4 rounded border border-green-30 h-full'>
                            <div className='flex gap-1 flex-col body-medium'>
                                <span className='uppercase text-green-500'>completion percentage</span>
                                <span className='text-2xl font-bold'>{leaderboardMetricesData?.completionPercentage.toFixed(2)}</span>
                            </div>
                        </div>
                    </div> */}
          <div className="p-4 border-r border-l md:border-l-0 border-green-30 ">
            <div className="p-4 flex flex-col gap-10 rounded border border-green-30 h-full">
              <div className="flex gap-1 flex-col body-medium">
                <span className="uppercase text-green-500">quests completed</span>
                <span className="text-2xl font-bold">{leaderboardMetricesData?.questCompleted}</span>
              </div>
              {/* <div className='bg-green-10 w-fit flex gap-2 items-center px-4 py-2 rounded-full text-white'>
                                <IcQuestRemain fillcolor="#1A1C22"/>
                                out of {leaderboardMetricesData?.totalQuest} Quests
                            </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="min-h-[250px] mx-3 lg:h-[60vh] overflow-x-auto sm:overflow-hidden border-l border-r border-b border-green-30">
        <div className="relative flex flex-col min-h-[250px] lg:h-full w-[200%] sm:w-full">
          <div className="flex p-4  items-center text-green-500 border-b border-green-30">
            <span className="w-32">Rank</span>
            <span className="w-5/12">Account</span>
            <span className="w-3/12">Points</span>
            {/* <span className='w-3/12'>Quests Completed</span> */}
          </div>
          <div className="overflow-y-auto grow">
            {leaderboardRankingsData?.getData?.map((item: any, index: number) => {
              return <LeaderboardItem key={index} item={item} />;
            })}
          </div>
          <div className="w-full flex justify-between py-[14px] text-[14px] px-4">
            <div className="flex gap-3 items-center">
              {/* <ul className="flex">
                                {perPageItems.map((item: number, index: number) => (
                                    <li key={'page-items-' + index} onClick={() => {
                                        setPerPageCount(item);
                                        setCurrentPage(1);
                                    }} className={`py-2 px-3 border-2 border-monochrome-20  first:rounded-l-lg last:rounded-r-lg  cursor-pointer bg-monochrome-60 text-monochrome-20 ${item == perPageCount ? '!border-green-500 !bg-green-10 !text-green-500 ' : ''}`}>{item}</li>
                                ))}
                            </ul>
                            <span className="text-monochrome-20">Items per page</span> */}
            </div>
            {/* <div className="flex items-center gap-2">
                            <span className="text-monochrome-20">Page</span>
                            <input className="!bg-monochrome-60 !text-monochrome-20 !w-[40px] text-center !py-1 !px-0 rounded-lg !m-0 !min-h-[auto]" type="text" value={currentPage} min={1} onChange={(e) => setCurrentPage(Number(e.target.value))} />
                            <span className="text-monochrome-20">of {totalPages}</span>
                            <div className="grid grid-cols-2 border-2 border-monochrome-20 divide-monochrome-20 divide-x rounded-lg items-center">
                                <button className="p-2 bg-monochrome-60 rounded-l-lg" onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)}
                                    disabled={currentPage === 1}><IcPreviousPage /></button>
                                <button className="p-2 bg-monochrome-60 rounded-r-lg" onClick={() =>
                                    setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages)
                                }
                                    disabled={currentPage === totalPages}><IcNextPage /></button>
                            </div>
                        </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Leaderboard;
