import React from "react";

const IcRedCardInnerShadow = (props: any) => {
  return (
    <div {...props}>
      <svg width="116.2px" height="104.57" viewBox="0 0 166 149.39" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* Polygone */}
        <path d="M 124.831 0 L 166 74.7 L 124.831 149.39 L 41.169 149.39 L 0 74.7 L 41.169 0 L 124.831 0 Z" fill="#1A1C22" />

        {/* Border */}
        {props.lightWhite ? (
          <path
            d="M124.831 0.4945L166 74.7L124.831 149.39H41.169L0 74.7L41.169 0.4945H124.831Z"
            fill="#1A1C22"
            stroke="white"
            strokeWidth="0.829545"
            strokeOpacity="0.2"
          />
        ) : (
          <path
            d="M124.831 0.4945L166 74.7L124.831 149.39H41.169L0 74.7L41.169 0.4945H124.831Z"
            fill="#1A1C22"
            stroke="white"
            strokeWidth="0.829545"
            strokeOpacity="1"
          />
        )}
        <mask id="mask0_193_478343" maskUnits="userSpaceOnUse" x="1" y="0" width="164" height="144">
          <path
            d="M120.646 0.119875C123.017 0.119875 125.208 1.38561 126.393 3.4401L164.04 68.6811C165.225 70.7349 165.225 73.2648 164.04 75.3182L126.392 140.562C125.207 142.615 123.017 143.88 120.647 143.88L45.3547 143.88C42.9837 143.88 40.7925 142.614 39.607 140.56L1.96074 75.3188C0.775652 73.2651 0.775505 70.7351 1.96035 68.6818L39.6082 3.43806C40.7931 1.38472 42.9829 0.119876 45.3531 0.119876L120.646 0.119875Z"
            fill="#00CC9C"
          />
        </mask>

        {props.isShadowGray && (
          <>
            <g>
              <path d="M41.5 0L0 74.7L41.5 149.4L124.5 149.4L166 74.7L124.5 0L41.5 0Z" fill="#6b7280" />
            </g>
          </>
        )}

        {/* Circuit */}
        <g mask="url(#mask0_193_478343)">
          <g filter="url(#filter0_f_193_478343)">
            <ellipse
              cx="24.0601"
              cy="24.0667"
              rx="24.0601"
              ry="24.0667"
              transform="matrix(0.865907 0.500205 -0.499795 0.866144 99.2559 141.77)"
              fill="#D9D9D9"
              fillOpacity="0.4"
            />
          </g>
          <g filter="url(#filter1_f_193_478343)">
            <ellipse
              cx="24.0601"
              cy="24.0667"
              rx="24.0601"
              ry="24.0667"
              transform="matrix(0.865907 0.500205 -0.499795 0.866144 37.824 -42.2697)"
              fill="#D9D9D9"
              fillOpacity="0.4"
            />
          </g>
          <g clipPath="url(#clip0_193_478343)">
            <path d="M-3.07265 36.1833L-3.07265 -3.68495L7.14154 -13.8991L7.14154 -48.1661" stroke="white" strokeOpacity="0.05" strokeWidth="0.896025" />
            <path
              d="M-12.9586 36.1832L-12.9586 -3.68508L-12.9586 -34.9866L-4.06239 -43.8829L-4.06239 -80.1268L-4.06239 -123.29L6.48129 -133.834L6.4813 -169.089"
              stroke="white"
              strokeOpacity="0.05"
              strokeWidth="0.896025"
            />
            <path d="M-2.40924 112.626L-2.40924 152.494L7.14597 162.709L7.14597 196.975" stroke="white" strokeOpacity="0.05" strokeWidth="0.896025" />
            <path d="M4.2352 103.786L44.1035 103.786L54.3177 114H88.5847" stroke="white" strokeOpacity="0.05" strokeWidth="0.896025" />
            <rect width="9.8847" height="3.2949" transform="matrix(1 0 0 -1 4.2352 105.434)" fill="white" fillOpacity="0.05" />
            <circle cx="2.63592" cy="2.63592" r="2.63592" transform="matrix(1 0 0 -1 88.5847 116.636)" fill="white" fillOpacity="0.05" />
            <path d="M4.2352 43.8192L44.1035 43.8192L54.3177 34.2639L88.5847 34.2639" stroke="white" strokeOpacity="0.05" strokeWidth="0.896025" />
            <rect width="9.8847" height="3.2949" transform="matrix(1 0 0 -1 4.2352 45.4664)" fill="white" fillOpacity="0.05" />
            <circle cx="2.63592" cy="2.63592" r="2.63592" transform="matrix(1 0 0 -1 88.5847 36.8995)" fill="white" fillOpacity="0.05" />
            <path
              d="M4.2352 93.9017L44.1035 93.9017H75.4051L84.3013 102.798L120.545 102.798L163.708 102.798L174.252 113.342H209.508"
              stroke="white"
              strokeOpacity="0.05"
              strokeWidth="0.896025"
            />
            <rect width="9.8847" height="3.2949" transform="matrix(1 0 0 -1 4.2352 95.5495)" fill="white" fillOpacity="0.05" />
            <path
              d="M4.2352 84.0167L44.1035 84.0167H81.9949L90.8911 92.9129L127.135 92.9129L170.298 92.9129H183.807L193.033 102.139L234.219 102.139"
              stroke="white"
              strokeOpacity="0.05"
              strokeWidth="0.896025"
            />
            <rect width="9.8847" height="3.2949" transform="matrix(1 0 0 -1 4.2352 85.6644)" fill="white" fillOpacity="0.05" />
            <path
              d="M4.2352 74.1323L44.1035 74.1323L89.9026 74.1323L98.7989 83.0286H140.315L183.478 83.0286H196.987L206.213 92.2543L247.399 92.2543"
              stroke="white"
              strokeOpacity="0.05"
              strokeWidth="0.896025"
            />
            <rect width="9.8847" height="3.2949" transform="matrix(1 0 0 -1 4.2352 75.7796)" fill="white" fillOpacity="0.05" />
            <path
              d="M4.2352 64.248L44.1035 64.248L98.4694 64.248L107.366 73.1442H140.315L183.478 73.1442H202.918L212.144 67.2134L260.579 67.2134"
              stroke="white"
              strokeOpacity="0.05"
              strokeWidth="0.896025"
            />
            <rect width="9.8847" height="3.2949" transform="matrix(1 0 0 -1 4.2352 65.8956)" fill="white" fillOpacity="0.05" />
            <path
              d="M4.2352 54.3632L44.1035 54.3632L111.649 54.3632L116.591 49.4208L224.335 49.4208"
              stroke="white"
              strokeOpacity="0.05"
              strokeWidth="0.896025"
            />
            <rect width="9.8847" height="3.2949" transform="matrix(1 0 0 -1 4.2352 56.0101)" fill="white" fillOpacity="0.05" />
            <path d="M117.578 64.5771L171.285 64.5771L177.216 58.6463L207.199 58.6463" stroke="white" strokeOpacity="0.05" strokeWidth="0.896025" />
            <circle
              cx="2.63592"
              cy="2.63592"
              r="2.18791"
              transform="matrix(1 0 0 -1 112.966 67.2131)"
              stroke="white"
              strokeOpacity="0.05"
              strokeWidth="0.896025"
            />
            <path d="M101.764 34.2649L155.47 34.2649L161.401 38.8778L191.385 38.8778" stroke="white" strokeOpacity="0.05" strokeWidth="0.896025" />
            <circle
              cx="2.63592"
              cy="2.63592"
              r="2.18791"
              transform="matrix(1 0 0 -1 97.1511 36.9006)"
              stroke="white"
              strokeOpacity="0.05"
              strokeWidth="0.896025"
            />
            <path d="M67.3425 183.765L39.1514 155.573L39.1514 141.128L14.921 116.898" stroke="white" strokeOpacity="0.05" strokeWidth="0.896025" />
            <circle
              cx="2.63592"
              cy="2.63592"
              r="2.63592"
              transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 16.7857 115.034)"
              fill="white"
              fillOpacity="0.05"
            />
            <path
              d="M140.573 233.895L140.573 221.314L117.274 198.016L86.7531 167.495L73.007 153.749L62.2897 151.419L28.041 117.17"
              stroke="white"
              strokeOpacity="0.05"
              strokeWidth="0.896025"
            />
            <circle
              cx="2.63592"
              cy="2.63592"
              r="2.63592"
              transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 31.3023 116.705)"
              fill="white"
              fillOpacity="0.05"
            />
            <path d="M138.407 193.642L100.43 155.665L92.0429 155.665L70.8413 134.464" stroke="white" strokeOpacity="0.05" strokeWidth="0.896025" />
            <circle
              cx="2.63592"
              cy="2.63592"
              r="2.18791"
              transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 73.1705 133.065)"
              stroke="white"
              strokeOpacity="0.05"
              strokeWidth="0.896025"
            />
            <path d="M138.917 125.803L85.2097 125.803L79.2789 121.19L49.2953 121.19" stroke="white" strokeOpacity="0.05" strokeWidth="0.896025" />
            <circle
              cx="2.63592"
              cy="2.63592"
              r="2.18791"
              transform="matrix(-1 8.74228e-08 8.74228e-08 1 143.529 123.167)"
              stroke="white"
              strokeOpacity="0.05"
              strokeWidth="0.896025"
            />
            <circle
              cx="2.63592"
              cy="2.63592"
              r="2.18791"
              transform="matrix(-1 8.74228e-08 8.74228e-08 1 49.9549 118.554)"
              stroke="white"
              strokeOpacity="0.05"
              strokeWidth="0.896025"
            />
            <path d="M72.5388 200.197L34.5623 162.221L33.6304 154.765L12.4288 133.564" stroke="white" strokeOpacity="0.05" strokeWidth="0.896025" />
            <circle
              cx="2.63592"
              cy="2.63592"
              r="2.18791"
              transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 14.7595 132.166)"
              stroke="white"
              strokeOpacity="0.05"
              strokeWidth="0.896025"
            />
            <path d="M67.3398 -35.98L39.1487 -7.78883L39.1487 6.65622L14.9183 30.8866" stroke="white" strokeOpacity="0.05" strokeWidth="0.896025" />
            <circle cx="13.0542" cy="32.7505" r="2.63592" transform="rotate(135 13.0542 32.7505)" fill="white" fillOpacity="0.05" />
            <path
              d="M140.57 -86.1115L140.57 -73.5303L117.271 -50.2319L86.7504 -19.7109L73.0043 -5.96479L62.287 -3.63494L28.0382 30.6138"
              stroke="white"
              strokeOpacity="0.05"
              strokeWidth="0.896025"
            />
            <circle cx="27.5719" cy="31.0797" r="2.63592" transform="rotate(135 27.5719 31.0797)" fill="white" fillOpacity="0.05" />
            <path d="M138.404 -45.857L100.428 -7.8805L92.0403 -7.8805L70.8387 13.3211" stroke="white" strokeOpacity="0.05" strokeWidth="0.896025" />
            <circle cx="69.4401" cy="14.7194" r="2.18791" transform="rotate(135 69.4401 14.7194)" stroke="white" strokeOpacity="0.05" strokeWidth="0.896025" />
            <path d="M138.916 21.9813L85.209 21.9812L79.2782 26.5941L49.2946 26.5941" stroke="white" strokeOpacity="0.05" strokeWidth="0.896025" />
            <circle cx="140.892" cy="21.9811" r="2.18791" transform="rotate(-180 140.892 21.9811)" stroke="white" strokeOpacity="0.05" strokeWidth="0.896025" />
            <circle cx="47.3171" cy="26.5937" r="2.18791" transform="rotate(-180 47.3171 26.5937)" stroke="white" strokeOpacity="0.05" strokeWidth="0.896025" />
            <circle cx="9.05408" cy="-0.1969" r="2.18791" transform="rotate(135 9.05408 -0.1969)" stroke="white" strokeOpacity="0.05" strokeWidth="0.896025" />
            <path d="M72.5386 -52.414L34.5621 -14.4375L33.6302 -6.982L12.4285 14.2196" stroke="white" strokeOpacity="0.05" strokeWidth="0.896025" />
            <circle cx="11.0306" cy="15.6178" r="2.18791" transform="rotate(135 11.0306 15.6178)" stroke="white" strokeOpacity="0.05" strokeWidth="0.896025" />
            <path d="M160.183 -47.7722L198.159 -9.79566L199.091 -2.34015L220.293 18.8615" stroke="white" strokeOpacity="0.05" strokeWidth="0.896025" />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_f_193_478343"
            x="1.04588"
            y="67.6212"
            width="214.031"
            height="214.057"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="41.4773" result="effect1_foregroundBlur_193_478343" />
          </filter>
          <filter
            id="filter1_f_193_478343"
            x="-60.386"
            y="-116.418"
            width="214.031"
            height="214.057"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="41.4773" result="effect1_foregroundBlur_193_478343" />
          </filter>
          <clipPath id="clip0_193_478343">
            <rect width="211.462" height="239.669" fill="white" transform="translate(-46.3372 -48.2289)" />
          </clipPath>
        </defs>

        {props.isShadowRed && (
          <>
            <g filter="url(#filter0_i_147_481508)">
              <path d="M41.5 0L0 74.7L41.5 149.4L124.5 149.4L166 74.7L124.5 0L41.5 0Z" fill="red" />
            </g>
            <defs>
              <filter id="filter0_i_147_481508" x="0" y="0" width="166" height="149.39" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx="0" dy="0" />
                <feGaussianBlur stdDeviation="20" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_147_481508" />
              </filter>
            </defs>
          </>
        )}

        {props.isShadowGolden && (
          <>
            <g filter="url(#filter0_i_147_481508)">
              <path d="M41.5 0L0 74.7L41.5 149.4L124.5 149.4L166 74.7L124.5 0L41.5 0Z" fill="#D4AF37" />
            </g>
            <defs>
              <filter id="filter0_i_147_481508" x="0" y="0" width="166" height="149.39" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx="0" dy="0" />
                <feGaussianBlur stdDeviation="20" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_147_481508" />
              </filter>
            </defs>
          </>
        )}
      </svg>

      {/* <svg xmlns="http://www.w3.org/2000/svg" width="166" height="149.39" viewBox="0 0 166 149.39" fill="none">
        <g filter="url(#filter0_i_147_481508)">
          <path d="M41.5 0L0 74.7L41.5 149.4L124.5 149.4L166 74.7L124.5 0L41.5 0Z" fill="currentColor" />
        </g>
        {props.isShadow && (
          <defs>
            <filter id="filter0_i_147_481508" x="0" y="0" width="166" height="149.39" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dx="0" dy="0" />
              <feGaussianBlur stdDeviation="20" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
              <feBlend mode="normal" in2="shape" result="effect1_innerShadow_147_481508" />
            </filter>
          </defs>
        )}
      </svg> */}
    </div>
  );
};

export default IcRedCardInnerShadow;
