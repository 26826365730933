import { IcChecked, IcLock, IcPatternHex, IcWhiteLockUnlocked } from "@/assets";
import { isChallengeCompleted, isChallengeExpired, isBonusCardDestroyed, isFlipCardClaimed } from "./ChallengeService";
import useFlipCardStore from "@/store/useFlipCardStore";
import FlipCard from "./Reward";
import RemainingTimeCountdown from "@/components/UI/RemainingTimeCountdown";
import toast from "react-hot-toast";

const ChallengeRow3 = ({
  remainingTime,
  handleClickOfFlipCard,
  isQuestLocked,
  setFlipCardModalOpen,
  refetch,
  refetchStatistics,
  setDetailsToShowInModal,
  setLoading,
}: any) => {
  const { challenges, flipCards, bonusFlipCards, setFlipCards } = useFlipCardStore();
  return (
    <div className="grid relative">
      {/* HIDDEN */}
      <div className="w-[116.2px] relative aspect-[1/0.9]">
        {/* <img src='/assets/pattern3.png' className='blur-[1px] z-30 hexagon bg-monochrome-100 absolute bottom-[-150%] right-[-75%]' />
                <div className='z-50 hexagon absolute bottom-[-150%] right-[-75%]' >
                    <div className='w-full h-full flex justify-center items-center'>
                        <IcQuestionmark />
                    </div>
                </div> */}
      </div>

      {/* CH3 */}
      <div className="w-[116.2px] relative aspect-[1/0.9]">
        <div
          className={`z-50 hexagon ${
            isChallengeCompleted(challenges, "CH3")
              ? isChallengeExpired(challenges, "CH3")
                ? "bg-gray-500"
                : "bg-green-500"
              : isChallengeExpired(challenges, "CH3")
              ? "bg-gray-500"
              : "bg-green-500"
          } absolute bottom-[-100.5%]`}
          onClick={() => {
            isQuestLocked("CH3", true)
              ? ""
              : isChallengeCompleted(challenges, "CH3")
              ? ""
              : isChallengeExpired(challenges, "CH3")
              ? ""
              : remainingTime > 0
              ? toast.error("Please wait until challenge is unlocked.")
              : setFlipCardModalOpen(true);
          }}
        >
          <IcPatternHex className="w-full h-full absolute top-0 left-0 text-white" />
          {!isChallengeCompleted(challenges, "CH3") && isChallengeExpired(challenges, "CH3") ? (
            <div className="flex items-center justify-center w-full h-full">
              <img src="/assets/penomo_white_logo.png" className="w-4 h-4" alt="white-penomo-logo" />
            </div>
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              {isQuestLocked("CH3", false) ? (
                <IcLock className={"text-black"} />
              ) : (
                <>
                  {isChallengeCompleted(challenges, "CH3") ? (
                    <IcChecked className={"text-black"} />
                  ) : remainingTime ? (
                    <RemainingTimeCountdown remainingTime={remainingTime} />
                  ) : (
                    <>
                      <IcWhiteLockUnlocked className={"text-black"} />
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* RW15 */}
      <FlipCard flipCardClass="bottom-[-50%] right-[-75%] z-50" flipCardId="RW15" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* RW13 -Red Card */}
      <FlipCard
        flipCardClass=""
        isShadowRed={isFlipCardClaimed(flipCards, "RW13") ? true : false}
        flipCardId="RW13"
        refetch={refetch}
        refetchStatistics={refetchStatistics}
        setLoading={setLoading}
      />

      {/* RW16 */}
      <FlipCard flipCardClass="top-[-50.8%] right-[-75%] z-50" flipCardId="RW16" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* RW14 - Bonus */}
      <FlipCard
        flipCardClass="top-[-100.7%]"
        isShadowRed={isBonusCardDestroyed(bonusFlipCards, "RW14", flipCards) ? true : false}
        flipCardId="RW14"
        refetch={refetch}
        refetchStatistics={refetchStatistics}
        isBonusCard
        setLoading={setLoading}
      />

      {/* HIDDEN */}
      <div className="w-[116.2px] relative aspect-[1/0.9]">
        {/* <img src='/assets/pattern3.png' className='blur-[1px] z-30 hexagon bg-monochrome-100 absolute top-[-150%] right-[-75%]' />
                <div className='z-50 hexagon absolute top-[-150%] right-[-75%] '>
                    <div className='w-full h-full flex justify-center items-center'>
                        <IcQuestionmark />
                    </div>  
                </div> */}
      </div>
    </div>
  );
};

export default ChallengeRow3;
