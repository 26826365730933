import ChallengeRow4 from "./ChallengeRow4";
import ChallengeRow5 from "./ChallengeRow5";
import ChallengeRow6 from "./ChallengeRow6";

const Tree2 = ({ remainingTime, isQuestLocked, setFlipCardModalOpen, refetchStatistics, refetch, setDetailsToShowInModal, setPeaqRewardModal, setLoading }: any) => {
  const rows = [ChallengeRow4, ChallengeRow5, ChallengeRow6];

  return (
    <>
      <div className="relative flex-none">
        <div className="flex gap-[58px] ml-[56px] mb-[-26.5px]">
          {rows.map((Row, index) => {
            return (
              <Row
                key={index}
                remainingTime={remainingTime}
                isQuestLocked={isQuestLocked}
                setFlipCardModalOpen={setFlipCardModalOpen}
                refetchStatistics={refetchStatistics}
                refetch={refetch}
                setDetailsToShowInModal={setDetailsToShowInModal}
                setPeaqRewardModal={setPeaqRewardModal}
                setLoading={setLoading}
              />
            );
          })}
        </div>
        <img src="/assets/stand2.png" className=" w-[500px] absolute z-20 bottom-[-51px] left-8 object-cover" alt="grass" />
      </div>
    </>
  );
};

export default Tree2;
