import ChallengeRow7 from "./ChallengeRow7";
import ChallengeRow8 from "./ChallengeRow8";
import ChallengeRow9 from "./ChallengeRow9";

const Tree3 = ({ remainingTime, isQuestLocked, setFlipCardModalOpen, refetchStatistics, refetch, setDetailsToShowInModal, setLoading }: any) => {
  const rows = [ChallengeRow7, ChallengeRow8, ChallengeRow9];

  return (
    <>
      <div className="relative flex-none">
        <div className="flex gap-[55px] ml-[56px] mb-[-26.5px]">
          {rows.map((Row, index) => {
            return (
              <Row
                key={index}
                remainingTime={remainingTime}
                isQuestLocked={isQuestLocked}
                setFlipCardModalOpen={setFlipCardModalOpen}
                refetchStatistics={refetchStatistics}
                refetch={refetch}
                setDetailsToShowInModal={setDetailsToShowInModal}
                setLoading={setLoading}
              />
            );
          })}
        </div>
        <img src="/assets/stand2.png" className="w-[500px] absolute z-20 bottom-[-51px] left-[33px] object-cover" alt="grass" />
      </div>
    </>
  );
};

export default Tree3;
