import { IcChecked, IcLock, IcPatternHex, IcWhiteLockUnlocked } from "@/assets";
import { isChallengeCompleted, isChallengeExpired, isFlipCardClaimed } from "./ChallengeService";
import FlipCard from "./Reward";
import useFlipCardStore from "@/store/useFlipCardStore";
import RemainingTimeCountdown from "@/components/UI/RemainingTimeCountdown";
import toast from "react-hot-toast";

const ChallengeRow9 = ({ remainingTime, isQuestLocked, setFlipCardModalOpen, refetch, refetchStatistics, setDetailsToShowInModal, setLoading }: any) => {
  const { challenges, flipCards, bonusFlipCards } = useFlipCardStore();
  return (
    <div className="grid relative ml-[3px]">
      {/* HIDDEN */}
      <div className="w-[116.2px] relative aspect-[1/0.9]">
        {/* <img src='/assets/pattern3.png' className='blur-[1px] z-30 hexagon bg-monochrome-100 absolute bottom-[-150%] right-[-75%]' />
        <div className='z-50 hexagon absolute bottom-[-150%] right-[-75%]' >
            <div className='w-full h-full flex justify-center items-center'>
                <IcQuestionmark />
            </div>
        </div> */}
      </div>

      {/* RW45 */}
      <FlipCard flipCardClass="bottom-[-100.5%] z-50" flipCardId="RW45" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* RW48 */}
      <FlipCard flipCardClass="bottom-[-50%] right-[-75%] z-50" flipCardId="RW48" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* RW46 */}
      <FlipCard flipCardClass="" flipCardId="RW46" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* CH9 */}
      <div className="w-[116.2px] relative aspect-[1/0.9]">
        <div
          className={`z-50 hexagon ${
            isChallengeCompleted(challenges, "CH9")
              ? isChallengeExpired(challenges, "CH9")
                ? "bg-gray-500"
                : "bg-green-500"
              : isChallengeExpired(challenges, "CH9")
              ? "bg-gray-500"
              : "bg-green-500"
          } absolute top-[-50.5%] right-[-75%]`}
          onClick={() => {
            isQuestLocked("CH9", true)
              ? ""
              : isChallengeCompleted(challenges, "CH9")
              ? ""
              : isChallengeExpired(challenges, "CH9")
              ? ""
              : remainingTime > 0
              ? toast.error("Please wait until challenge is unlocked.")
              : setFlipCardModalOpen(true);
          }}
        >
          <IcPatternHex className="w-full h-full absolute top-0 left-0 text-white" />
          {!isChallengeCompleted(challenges, "CH9") && isChallengeExpired(challenges, "CH9") ? (
            <div className="flex items-center justify-center w-full h-full">
              <img src="/assets/penomo_white_logo.png" className="w-4 h-4" alt="white-penomo-logo" />
            </div>
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              {isQuestLocked("CH9", false) ? (
                <IcLock className={"text-black"} />
              ) : (
                <>
                  {isChallengeCompleted(challenges, "CH9") ? (
                    <IcChecked className={"text-black"} />
                  ) : remainingTime ? (
                    <RemainingTimeCountdown remainingTime={remainingTime} />
                  ) : (
                    <>
                      <IcWhiteLockUnlocked className={"text-black"} />
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* RW47 - Red Card */}
      <FlipCard
        flipCardClass="top-[-100.5%]"
        isShadowRed={isFlipCardClaimed(flipCards, "RW47") ? true : false}
        flipCardId="RW47"
        refetch={refetch}
        refetchStatistics={refetchStatistics}
        setLoading={setLoading}
      />

      {/* HIDDEN */}
      <div className="w-[116.2px] relative aspect-[1/0.9]">
        {/* <img src='/assets/pattern3.png' className='blur-[1px] z-30 hexagon bg-monochrome-100 absolute top-[-150%] right-[-75%]' />
        <div className='z-50 hexagon absolute top-[-150%] right-[-75%] '>
            <div className='w-full h-full flex justify-center items-center'>
                <IcQuestionmark />
            </div>
        </div> */}
      </div>
    </div>
  );
};

export default ChallengeRow9;
