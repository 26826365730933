const IcParticipants = (props: any) => {
    return (
        <div {...props}>
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.875 13.75C7.875 13.75 6.75 13.75 6.75 12.625C6.75 11.5 7.875 8.125 12.375 8.125C16.875 8.125 18 11.5 18 12.625C18 13.75 16.875 13.75 16.875 13.75H7.875ZM12.375 7C13.2701 7 14.1286 6.64442 14.7615 6.01149C15.3944 5.37855 15.75 4.52011 15.75 3.625C15.75 2.72989 15.3944 1.87145 14.7615 1.23851C14.1286 0.605579 13.2701 0.25 12.375 0.25C11.4799 0.25 10.6214 0.605579 9.98851 1.23851C9.35558 1.87145 9 2.72989 9 3.625C9 4.52011 9.35558 5.37855 9.98851 6.01149C10.6214 6.64442 11.4799 7 12.375 7ZM5.868 13.75C5.7013 13.3988 5.61813 13.0137 5.625 12.625C5.625 11.1006 6.39 9.53125 7.803 8.44C7.09783 8.22224 6.36297 8.11596 5.625 8.125C1.125 8.125 0 11.5 0 12.625C0 13.75 1.125 13.75 1.125 13.75H5.868ZM5.0625 7C5.80842 7 6.52379 6.70368 7.05124 6.17624C7.57868 5.64879 7.875 4.93342 7.875 4.1875C7.875 3.44158 7.57868 2.72621 7.05124 2.19876C6.52379 1.67132 5.80842 1.375 5.0625 1.375C4.31658 1.375 3.60121 1.67132 3.07376 2.19876C2.54632 2.72621 2.25 3.44158 2.25 4.1875C2.25 4.93342 2.54632 5.64879 3.07376 6.17624C3.60121 6.70368 4.31658 7 5.0625 7Z" fill="currentColor" />
            </svg>

        </div>
    )
}

export default IcParticipants