import { IcChecked, IcLock, IcPatternHex, IcWhiteLockUnlocked } from "@/assets";
import { isChallengeCompleted, isChallengeExpired, isBonusCardDestroyed, isFlipCardClaimed } from "./ChallengeService";
import useFlipCardStore from "@/store/useFlipCardStore";
import FlipCard from "./Reward";
import RemainingTimeCountdown from "@/components/UI/RemainingTimeCountdown";
import toast from "react-hot-toast";

const ChallengeRow5 = ({ remainingTime, isQuestLocked, setFlipCardModalOpen, refetch, refetchStatistics, setDetailsToShowInModal, setLoading }: any) => {
  const { challenges, flipCards, bonusFlipCards } = useFlipCardStore();
  return (
    <>
      <div className="grid relative">
        {/* RW25 - Bonus */}
        <FlipCard
          flipCardClass="bottom-[-150%] right-[-75%]"
          isShadowRed={isBonusCardDestroyed(bonusFlipCards, "RW25", flipCards) ? true : false}
          flipCardId="RW25"
          refetch={refetch}
          refetchStatistics={refetchStatistics}
          isBonusCard
          setLoading={setLoading}
        />

        {/* RW23 - Red Card */}
        <FlipCard
          flipCardClass="bottom-[-100.5%] z-50"
          isShadowRed={isFlipCardClaimed(flipCards, "RW23") ? true : false}
          flipCardId="RW23"
          refetch={refetch}
          refetchStatistics={refetchStatistics}
          setLoading={setLoading}
        />

        {/* RW26 */}
        <FlipCard flipCardClass="bottom-[-50%] right-[-75%]" flipCardId="RW26" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

        {/* CH5 */}
        <div className="w-[116.2px] relative aspect-[1/0.9]">
          <div
            className={`z-50 hexagon ${
              isChallengeCompleted(challenges, "CH5")
                ? isChallengeExpired(challenges, "CH5")
                  ? "bg-gray-500"
                  : "bg-green-500"
                : isChallengeExpired(challenges, "CH5")
                ? "bg-gray-500"
                : "bg-green-500"
            }`}
            onClick={() => {
              isQuestLocked("CH5", true)
                ? ""
                : isChallengeCompleted(challenges, "CH5")
                ? ""
                : isChallengeExpired(challenges, "CH5")
                ? ""
                : remainingTime > 0
                ? toast.error("Please wait until challenge is unlocked.")
                : setFlipCardModalOpen(true);
            }}
          >
            <IcPatternHex className="w-full h-full absolute top-0 left-0 text-white" />
            {!isChallengeCompleted(challenges, "CH5") && isChallengeExpired(challenges, "CH5") ? (
              <div className="flex items-center justify-center w-full h-full">
                <img src="/assets/penomo_white_logo.png" className="w-4 h-4" alt="white-penomo-logo" />
              </div>
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                {isQuestLocked("CH5", false) ? (
                  <IcLock className={"text-black"} />
                ) : (
                  <>
                    {isChallengeCompleted(challenges, "CH5") ? (
                      <IcChecked className={"text-black"} />
                    ) : remainingTime ? (
                      <RemainingTimeCountdown remainingTime={remainingTime} />
                    ) : (
                      <>
                        <IcWhiteLockUnlocked className={"text-black"} />
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        {/* RW27 - Red Card */}
        <FlipCard
          flipCardClass="top-[-50%] right-[-75%]"
          isShadowRed={isFlipCardClaimed(flipCards, "RW27") ? true : false}
          flipCardId="RW27"
          refetch={refetch}
          refetchStatistics={refetchStatistics}
          setLoading={setLoading}
        />

        {/* RW24 */}
        <FlipCard flipCardClass="top-[-100.5%]" flipCardId="RW24" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

        {/* RW28 - Bonus */}
        <FlipCard
          flipCardClass="top-[-150.6%] right-[-75%]"
          isShadowRed={isBonusCardDestroyed(bonusFlipCards, "RW28", flipCards) ? true : false}
          flipCardId="RW28"
          refetch={refetch}
          refetchStatistics={refetchStatistics}
          isBonusCard
          setLoading={setLoading}
        />
      </div>
    </>
  );
};

export default ChallengeRow5;
