import { isBonusCardDestroyed } from "./ChallengeService";
import useFlipCardStore from "@/store/useFlipCardStore";
import FlipCard from "./Reward";

const ChallengeRow12 = ({ isQuestLocked, setFlipCardModalOpen, refetch, refetchStatistics, setDetailsToShowInModal, setLoading }: any) => {
  const { challenges, flipCards, bonusFlipCards,  setFlipGoldenCard } = useFlipCardStore();

  // const handleClaimGoldenCards = () => {
  //   claimAllGoldenCards().then((res: any) => {
  //     if (res.code == 200) {
  //       refetch();
  //       refetchStatistics();
  //       setFlipGoldenCard(true);
  //     }
  //   });
  // };

  return (
    <div className="grid relative ml-[2px]">
      {/* HIDDEN */}
      <div className="w-[116.2px] relative aspect-[1/0.9]">
        {/* <img src='/assets/pattern3.png' className='blur-[1px] z-30 hexagon bg-monochrome-100 absolute bottom-[-150%] right-[-75%]' />
        <div className='z-50 hexagon absolute bottom-[-150%] right-[-75%]' >
            <div className='w-full h-full flex justify-center items-center'>
                <IcQuestionmark />
            </div>
        </div> */}
      </div>

      {/* RW62 - Bonus */}
      <FlipCard
        flipCardClass="bottom-[-100.5%]"
        isShadowRed={isBonusCardDestroyed(bonusFlipCards, "RW62", flipCards) ? true : false}
        flipCardId="RW62"
        refetch={refetch}
        refetchStatistics={refetchStatistics}
        isBonusCard
        setLoading={setLoading}
      />

      {/* CLICK HERE BUTTON */}
      <div className="w-[116.2px] relative aspect-[1/0.9]">
        {/* <div className="z-50 hexagon  absolute bottom-[-100%] right-[-100%]">
          <IcRedCardInnerShadow isShadowGolden={shouldEnableButton ? true : false} className="" />
          <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] text-center z-50 text-base">
            {shouldEnableButton ? (
              <button className="cursor-pointer" onClick={handleClaimGoldenCards} disabled={shouldDisableButton ? true : false}>
                <img src="/assets/penomo_white_logo.png" className="w-6 h-6 mx-auto" alt="white-penomo-logo" />
                {shouldDisableButton ? "" : <span className="mt-1">Claim All Flip Cards</span>}
              </button>
            ) : (
              <div>
                <IcLock />
              </div>
            )}
          </div>
        </div> */}
      </div>

      {/* RW63 - Bonus */}
      <FlipCard
        flipCardClass=""
        isShadowRed={isBonusCardDestroyed(bonusFlipCards, "RW63", flipCards) ? true : false}
        flipCardId="RW63"
        refetch={refetch}
        refetchStatistics={refetchStatistics}
        isBonusCard
        setLoading={setLoading}
      />

      {/* HIDDEN */}
      <div className="w-[116.2px] relative aspect-[1/0.9]">
        {/* <img src='/assets/pattern3.png' className='blur-[1px] z-30 hexagon bg-monochrome-100 absolute top-[-50%] right-[-75%]' />
        <div className='z-50 hexagon absolute top-[-50%] right-[-75%]'>
            <div className='w-full h-full flex justify-center items-center'>
                <IcQuestionmark />
            </div>
        </div> */}
      </div>

      {/* RW64 - Bonus */}
      <FlipCard
        flipCardClass="top-[-100.5%]"
        isShadowRed={isBonusCardDestroyed(bonusFlipCards, "RW64", flipCards) ? true : false}
        flipCardId="RW64"
        refetch={refetch}
        refetchStatistics={refetchStatistics}
        isBonusCard
        setLoading={setLoading}
      />

      {/* HIDDEN */}
      <div className="w-[116.2px] relative aspect-[1/0.9]">
        {/* <img src='/assets/pattern3.png' className='blur-[1px] z-30 hexagon bg-monochrome-100 absolute top-[-150%] right-[-75%]' />
        <div className='z-50 hexagon absolute top-[-150%] right-[-75%] '>
            <div className='w-full h-full flex justify-center items-center'>
                <IcQuestionmark />
            </div>
        </div> */}
      </div>
    </div>
  );
};

export default ChallengeRow12;
