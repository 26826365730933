import { IcChecked, IcLock, IcPatternHex, IcWhiteLockUnlocked } from "@/assets";
import { isChallengeCompleted, isChallengeExpired, isBonusCardDestroyed, isFlipCardClaimed } from "./ChallengeService";
import useFlipCardStore from "@/store/useFlipCardStore";
import FlipCard from "./Reward";
import RemainingTimeCountdown from "@/components/UI/RemainingTimeCountdown";
import toast from "react-hot-toast";

const ChallengeRow8 = ({ remainingTime, isQuestLocked, setFlipCardModalOpen, refetch, refetchStatistics, setDetailsToShowInModal, setLoading }: any) => {
  const { challenges, flipCards, bonusFlipCards } = useFlipCardStore();

  return (
    <div className="grid relative ml-[3px]">
      {/* CH8 */}
      <div className="w-[116.2px] relative aspect-[1/0.9]">
        <div
          className={`z-50 hexagon ${
            isChallengeCompleted(challenges, "CH8")
              ? isChallengeExpired(challenges, "CH8")
                ? "bg-gray-500"
                : "bg-green-500"
              : isChallengeExpired(challenges, "CH8")
              ? "bg-gray-500"
              : "bg-green-500"
          } absolute bottom-[-150.5%] right-[-75%]`}
          onClick={() => {
            isQuestLocked("CH8", true)
              ? ""
              : isChallengeCompleted(challenges, "CH8")
              ? ""
              : isChallengeExpired(challenges, "CH8")
              ? ""
              : remainingTime > 0
              ? toast.error("Please wait until challenge is unlocked.")
              : setFlipCardModalOpen(true);
          }}
        >
          <IcPatternHex className="w-full h-full absolute top-0 left-0 text-white" />
          {!isChallengeCompleted(challenges, "CH8") && isChallengeExpired(challenges, "CH8") ? (
            <div className="flex items-center justify-center w-full h-full">
              <img src="/assets/penomo_white_logo.png" className="w-4 h-4" alt="white-penomo-logo" />
            </div>
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              {isQuestLocked("CH8", false) ? (
                <IcLock className={"text-black"} />
              ) : (
                <>
                  {isChallengeCompleted(challenges, "CH8") ? (
                    <IcChecked className={"text-black"} />
                  ) : remainingTime ? (
                    <RemainingTimeCountdown remainingTime={remainingTime} />
                  ) : (
                    <>
                      <IcWhiteLockUnlocked className={"text-black"} />
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* RW40 - Red Card */}
      <FlipCard
        flipCardClass="bottom-[-100.5%] z-50"
        isShadowRed={isFlipCardClaimed(flipCards, "RW40") ? true : false}
        flipCardId="RW40"
        refetch={refetch}
        refetchStatistics={refetchStatistics}
        setLoading={setLoading}
      />

      {/* RW42 */}
      <FlipCard flipCardClass="bottom-[-50%] right-[-75%]" flipCardId="RW42" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* CH7 */}
      <div className="w-[116.2px] relative aspect-[1/0.9]">
        <div
          className={`z-50 hexagon ${
            isChallengeCompleted(challenges, "CH7")
              ? isChallengeExpired(challenges, "CH7")
                ? "bg-gray-500"
                : "bg-green-500"
              : isChallengeExpired(challenges, "CH7")
              ? "bg-gray-500"
              : "bg-green-500"
          }`}
          onClick={() => {
            isQuestLocked("CH7", true)
              ? ""
              : isChallengeCompleted(challenges, "CH7")
              ? ""
              : isChallengeExpired(challenges, "CH7")
              ? ""
              : remainingTime > 0
              ? toast.error("Please wait until challenge is unlocked.")
              : setFlipCardModalOpen(true);
          }}
        >
          <IcPatternHex className="w-full h-full absolute top-0 left-0 text-white" />
          {!isChallengeCompleted(challenges, "CH7") && isChallengeExpired(challenges, "CH7") ? (
            <div className="flex items-center justify-center w-full h-full">
              <img src="/assets/penomo_white_logo.png" className="w-4 h-4" alt="white-penomo-logo" />
            </div>
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              {isQuestLocked("CH7", false) ? (
                <IcLock className={"text-black"} />
              ) : (
                <>
                  {isChallengeCompleted(challenges, "CH7") ? (
                    <IcChecked className={"text-black"} />
                  ) : remainingTime ? (
                    <RemainingTimeCountdown remainingTime={remainingTime} />
                  ) : (
                    <>
                      <IcWhiteLockUnlocked className={"text-black"} />
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* RW43 */}
      <FlipCard flipCardClass="top-[-50%] right-[-75%]" flipCardId="RW43" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* RW41 */}
      <FlipCard flipCardClass="top-[-100.2%]" flipCardId="RW41" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* RW44 - Bonus */}
      <FlipCard
        flipCardClass="top-[-150.8%] right-[-75%]"
        isShadowRed={isBonusCardDestroyed(bonusFlipCards, "RW44", flipCards) ? true : false}
        flipCardId="RW44"
        refetch={refetch}
        refetchStatistics={refetchStatistics}
        isBonusCard
        setLoading={setLoading}
      />
    </div>
  );
};

export default ChallengeRow8;
