import ChallengeRow10 from "./ChallengeRow10";
import ChallengeRow11 from "./ChallengeRow11";
import ChallengeRow12 from "./ChallengeRow12";

const Tree4 = ({ remainingTime, isQuestLocked, setFlipCardModalOpen, refetchStatistics, refetch, setDetailsToShowInModal, setLoading }: any) => {
  const rows = [ChallengeRow10, ChallengeRow11, ChallengeRow12];

  return (
    <>
      <div className="relative flex-none mr-20">
        <div className="flex gap-[56.4px] ml-[56px] mb-[-26.5px]">
          {rows.map((Row, index) => {
            return (
              <Row
                key={index}
                remainingTime={remainingTime}
                isQuestLocked={isQuestLocked}
                setFlipCardModalOpen={setFlipCardModalOpen}
                refetchStatistics={refetchStatistics}
                refetch={refetch}
                setDetailsToShowInModal={setDetailsToShowInModal}
                setLoading={setLoading}
              />
            );
          })}
        </div>
        <img src="/assets/stand2.png" className="w-[500px] absolute z-20 bottom-[-51px] left-[32px] object-cover" alt="grass" />
      </div>
    </>
  );
};

export default Tree4;
