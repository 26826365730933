import { IcChecked, IcLock, IcPatternHex, IcWhiteLockUnlocked } from "@/assets";
import { isChallengeCompleted, isChallengeExpired, isBonusCardDestroyed, isFlipCardClaimed } from "./ChallengeService";
import useFlipCardStore from "@/store/useFlipCardStore";
import FlipCard from "./Reward";
import RemainingTimeCountdown from "@/components/UI/RemainingTimeCountdown";
import toast from "react-hot-toast";

const ChallengeRow4 = ({
  remainingTime,
  isQuestLocked,
  setFlipCardModalOpen,
  refetch,
  refetchStatistics,
  setDetailsToShowInModal,
  setPeaqRewardModal,
  setLoading,
}: any) => {
  const { challenges, flipCards, bonusFlipCards } = useFlipCardStore();
  return (
    <div className="grid relative">
      {/* RW19 - Bonus */}
      <FlipCard
        flipCardClass="bottom-[-151%] right-[-75%]"
        isShadowRed={isBonusCardDestroyed(bonusFlipCards, "RW19", flipCards) ? true : false}
        flipCardId="RW19"
        refetch={refetch}
        refetchStatistics={refetchStatistics}
        isBonusCard
        setLoading={setLoading}
      />

      {/* RW17 - Bonus */}
      <FlipCard
        flipCardClass="bottom-[-100.5%]"
        isShadowRed={isBonusCardDestroyed(bonusFlipCards, "RW17", flipCards) ? true : false}
        flipCardId="RW17"
        refetch={refetch}
        refetchStatistics={refetchStatistics}
        isBonusCard
        setLoading={setLoading}
      />

      {/* RW20 */}
      <FlipCard flipCardClass="bottom-[-50%] right-[-75%]" flipCardId="RW20" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* RW18 - Red Card */}
      <FlipCard
        flipCardClass=""
        isShadowRed={isFlipCardClaimed(flipCards, "RW18") ? true : false}
        flipCardId="RW18"
        refetch={refetch}
        refetchStatistics={refetchStatistics}
        setLoading={setLoading}
      />

      {/* RW21 */}
      <FlipCard flipCardClass="top-[-50%] right-[-75%]" flipCardId="RW21" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading}   />

      {/* CH4 */}
      <div className="w-[116.2px] relative aspect-[1/0.9]">
        <div
          className={`z-50 hexagon ${
            isChallengeCompleted(challenges, "CH4")
              ? isChallengeExpired(challenges, "CH4")
                ? "bg-gray-500"
                : "bg-green-500"
              : isChallengeExpired(challenges, "CH4")
              ? "bg-gray-500"
              : "bg-green-500"
          } top-[-101%]`}
          onClick={() => {
            isQuestLocked("CH4", true)
              ? ""
              : isChallengeCompleted(challenges, "CH4")
              ? ""
              : isChallengeExpired(challenges, "CH4")
              ? ""
              : remainingTime > 0
              ? toast.error("Please wait until challenge is unlocked.")
              : setPeaqRewardModal(true);
          }}
        >
          <IcPatternHex className="w-full h-full absolute top-0 left-0 text-white" />
          {!isChallengeCompleted(challenges, "CH4") && isChallengeExpired(challenges, "CH4") ? (
            <div className="flex items-center justify-center w-full h-full">
              <img src="/assets/penomo_white_logo.png" className="w-4 h-4" alt="white-penomo-logo" />
            </div>
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              {isQuestLocked("CH4", false) ? (
                <IcLock className={"text-black"} />
              ) : (
                <>
                  {isChallengeCompleted(challenges, "CH4") ? (
                    <IcChecked className={"text-black"} />
                  ) : remainingTime ? (
                    <RemainingTimeCountdown remainingTime={remainingTime} />
                  ) : (
                    <>
                      <IcWhiteLockUnlocked className={"text-black"} />
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* RW22 */}
      <FlipCard flipCardClass="top-[-150%] right-[-74.7%] z-50" flipCardId="RW22" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />
    </div>
  );
};

export default ChallengeRow4;
