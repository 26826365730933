import { IcChecked, IcLock, IcPatternHex, IcWhiteLockUnlocked } from "@/assets";
import { isChallengeCompleted, isChallengeExpired, isBonusCardDestroyed } from "./ChallengeService";
import useFlipCardStore from "@/store/useFlipCardStore";
import FlipCard from "./Reward";
import RemainingTimeCountdown from "@/components/UI/RemainingTimeCountdown";
import toast from "react-hot-toast";

const ChallengeRow6 = ({ remainingTime, isQuestLocked, setFlipCardModalOpen, refetch, refetchStatistics, setDetailsToShowInModal, setLoading }: any) => {
  const { challenges, flipCards, bonusFlipCards } = useFlipCardStore();
  return (
    <div className="grid relative">
      {/* HIDDEN */}
      <div className="w-[116.2px] relative aspect-[1/0.9]">
        {/* <img src='/assets/pattern3.png' className='blur-[1px] z-30 hexagon bg-monochrome-100 absolute bottom-[-150%] right-[-75%]' />
        <div className='z-50 hexagon absolute bottom-[-150%] right-[-75%]' >
            <div className='w-full h-full flex justify-center items-center'>
                <IcQuestionmark />
            </div>
        </div> */}
      </div>

      {/* RW29 */}
      <FlipCard flipCardClass="bottom-[-100.5%] z-50" flipCardId="RW29" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* CH6 */}
      <div className="w-[116.2px] relative aspect-[1/0.9]">
        <div
          className={`z-50 hexagon ${
            isChallengeCompleted(challenges, "CH6")
              ? isChallengeExpired(challenges, "CH6")
                ? "bg-gray-500"
                : "bg-green-500"
              : isChallengeExpired(challenges, "CH6")
              ? "bg-gray-500"
              : "bg-green-500"
          } absolute bottom-[-50.5%] right-[-75%]`}
          onClick={() => {
            isQuestLocked("CH6", true)
              ? ""
              : isChallengeCompleted(challenges, "CH6")
              ? ""
              : isChallengeExpired(challenges, "CH6")
              ? ""
              : remainingTime > 0
              ? toast.error("Please wait until challenge is unlocked.")
              : setFlipCardModalOpen(true);
          }}
        >
          <IcPatternHex className="w-full h-full absolute top-0 left-0 text-white" />
          {!isChallengeCompleted(challenges, "CH6") && isChallengeExpired(challenges, "CH6") ? (
            <div className="flex items-center justify-center w-full h-full">
              <img src="/assets/penomo_white_logo.png" className="w-4 h-4" alt="white-penomo-logo" />
            </div>
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              {isQuestLocked("CH6", false) ? (
                <IcLock className={"text-black"} />
              ) : (
                <>
                  {isChallengeCompleted(challenges, "CH6") ? (
                    <IcChecked className={"text-black"} />
                  ) : remainingTime ? (
                    <RemainingTimeCountdown remainingTime={remainingTime} />
                  ) : (
                    <>
                      <IcWhiteLockUnlocked className={"text-black"} />
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* RW30 */}
      <FlipCard flipCardClass="" flipCardId="RW30" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* RW32 */}
      <FlipCard flipCardClass="top-[-50%] right-[-75%] z-50" flipCardId="RW32" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* RW31 - Bonus */}
      <FlipCard
        flipCardClass="top-[-100.5%] "
        isShadowRed={isBonusCardDestroyed(bonusFlipCards, "RW31", flipCards) ? true : false}
        flipCardId="RW31"
        refetch={refetch}
        refetchStatistics={refetchStatistics}
        isBonusCard
        setLoading={setLoading}
      />

      {/* HIDDEN */}
      <div className="w-[116.2px] relative aspect-[1/0.9]">
        {/* <img src='/assets/pattern3.png' className='blur-[1px] z-30 hexagon bg-monochrome-100 absolute top-[-150%] right-[-75%]' />
        <div className='z-50 hexagon absolute top-[-150%] right-[-75%] '>
            <div className='w-full h-full flex justify-center items-center'>
                <IcQuestionmark />
            </div>
        </div> */}
      </div>
    </div>
  );
};

export default ChallengeRow6;
