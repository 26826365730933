import { IcChecked, IcLock, IcPatternHex, IcWhiteLockUnlocked } from "@/assets";
import { isChallengeCompleted, isChallengeExpired, isBonusCardDestroyed, isFlipCardClaimed } from "./ChallengeService";
import useFlipCardStore from "@/store/useFlipCardStore";
import FlipCard from "./Reward";
import RemainingTimeCountdown from "@/components/UI/RemainingTimeCountdown";
import toast from "react-hot-toast";

const ChallengeRow14 = ({ remainingTime, isQuestLocked, setFlipCardModalOpen, refetch, refetchStatistics, setDetailsToShowInModal, setLoading }: any) => {
  const { challenges, flipCards, bonusFlipCards } = useFlipCardStore();
  return (
    <div className="grid relative ml-[2px]">
      {/* RW58 - Bonus */}
      <FlipCard
        flipCardClass="bottom-[-150%] right-[-75%]"
        isShadowRed={isBonusCardDestroyed(bonusFlipCards, "RW58", flipCards) ? true : false}
        flipCardId="RW58"
        refetch={refetch}
        refetchStatistics={refetchStatistics}
        isBonusCard
        setLoading={setLoading}
      />

      {/* RW56 - Red Card */}
      <FlipCard
        flipCardClass="bottom-[-100.5%] z-50"
        isShadowRed={isFlipCardClaimed(flipCards, "RW56") ? true : false}
        flipCardId="RW56"
        refetch={refetch}
        refetchStatistics={refetchStatistics}
        setLoading={setLoading}
      />

      {/* RW59 */}
      <FlipCard flipCardClass="bottom-[-50%] right-[-75%]" flipCardId="RW59" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading} />

      {/* CH10 */}
      <div className="w-[116.2px] relative aspect-[1/0.9]">
        <div
          className={`z-50 hexagon ${
            isChallengeCompleted(challenges, "CH10")
              ? isChallengeExpired(challenges, "CH10")
                ? "bg-gray-500"
                : "bg-green-500"
              : isChallengeExpired(challenges, "CH10")
              ? "bg-gray-500"
              : "bg-green-500"
          }`}
          onClick={() => {
            isQuestLocked("CH10", true)
              ? ""
              : isChallengeCompleted(challenges, "CH10")
              ? ""
              : isChallengeExpired(challenges, "CH10")
              ? ""
              : remainingTime > 0
              ? toast.error("Please wait until challenge is unlocked.")
              : setFlipCardModalOpen(true);
          }}
        >
          <IcPatternHex className="w-full h-full absolute top-0 left-0 text-white" />
          {!isChallengeCompleted(challenges, "CH10") && isChallengeExpired(challenges, "CH10") ? (
            <div className="flex items-center justify-center w-full h-full">
              <img src="/assets/penomo_white_logo.png" className="w-4 h-4" alt="white-penomo-logo" />
            </div>
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              {isQuestLocked("CH10", false) ? (
                <IcLock className={"text-black"} />
              ) : (
                <>
                  {isChallengeCompleted(challenges, "CH10") ? (
                    <IcChecked className={"text-black"} />
                  ) : remainingTime ? (
                    <RemainingTimeCountdown remainingTime={remainingTime} />
                  ) : (
                    <>
                      <IcWhiteLockUnlocked className={"text-black"} />
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* RW60 */}
      <FlipCard flipCardClass="top-[-50%] right-[-75%]" flipCardId="RW60" refetch={refetch} refetchStatistics={refetchStatistics} setLoading={setLoading}   />

      {/* RW57 - Red Card */}
      <FlipCard
        flipCardClass="top-[-100.5%]"
        isShadowRed={isFlipCardClaimed(flipCards, "RW57") ? true : false}
        flipCardId="RW57"
        refetch={refetch}
        refetchStatistics={refetchStatistics}
        setLoading={setLoading}
      />

      {/* RW61 - Bonus */}
      <FlipCard
        flipCardClass="top-[-150%] right-[-75%]"
        isShadowRed={isBonusCardDestroyed(bonusFlipCards, "RW61", flipCards) ? true : false}
        flipCardId="RW61"
        refetch={refetch}
        refetchStatistics={refetchStatistics}
        isBonusCard
        setLoading={setLoading}
      />
    </div>
  );
};

export default ChallengeRow14;
